import styled from "styled-components";
import { space } from "styled-system";
import type { PageHeaderSize } from "./types";

const paddingMap = {
  small: "12px",
  medium: "20px 16px"
};

export const HeaderContainer = styled.div<{ size: PageHeaderSize }>`
  background-color: var(--fd-colors-background-surface);
  border-bottom: 1px solid var(--fd-colors-border-subtle);
  border-radius: 4px 4px 0 0;
  display: flex;
  flex-direction: row;
  gap: 16px;
  height: max-content;
  justify-content: space-between;
  padding: ${({ size }) => paddingMap[size as PageHeaderSize]};

  ${space}
`;

export const HeaderSection = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 4px;
  justify-content: flex-start;
  width: 80%;
`;

export const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: max-content;
  align-items: center;
  gap: 8px;
`;

export const ActionContainer = styled.div`
  width: 20px;
  height: 20px;
  min-width: 20px;
  max-height: 20px;
`;

export const LinkWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  justify-content: center;
  width: max-content;
`;

export const Link = styled.a`
  color: var(--fd-colors-link-default-base);
  cursor: pointer;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;
  text-align: center;
  text-decoration: none;

  &:hover {
    color: var(--fd-colors-link-default-hover);
  }

  &:active {
    color: var(--fd-colors-link-default-hover);
  }
`;
