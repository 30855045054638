import React, { Component, Fragment } from "react";
import { noop, get, isEqual } from "lodash";

import RunnerInfoPastPerformance from "@tvg/ipp/src/components/RunnerInfoPastPerformance";
import { MyBetsPastPerformanceTab } from "@tvg/sh-lib-my-bets/utils/types";
import { Icon as DSIcon, Paragraph } from "@tvg/design-system/web";

import FeedbackOverlay from "../../_atom/FeedbackOverlay";
import InlinePastPerformanceInfo from "../../_molecule/PastPerformanceRace";
import MTPNotification from "../../_molecule/MTPNotification";
import { mtpNotificationConfigDefault } from "../../__mocks__/ipp-pastRaces.mock";
import RaceOfficials from "../RaceOfficials";
import Video from "../../_organism/Video";
import { info } from "../../_static/Icons/icons";
import Icon from "../../_static/Icons";

import buildColor from "../../_static/ColorPalette";
import {
  InfoContainer,
  LoadingState,
  ContentWrapper,
  ModalWrapper,
  VideoContainer,
  NoReplayContainer,
  NoReplayContainerMessage,
  IconContainer,
  MessageContainer,
  UnavailableVideoContainer
} from "./styled-components";

export default class HorsePastPerformances extends Component {
  static defaultProps = {
    runner: null,
    hasNextRace: false,
    hasPreviousRace: false,
    onPreviousRaceNavigation: noop,
    onNextRaceNavigation: noop,
    results: null,
    onTabClick: noop,
    onModalClose: noop,
    withBorders: false,
    videoFullscreenTilt: false,
    isLoading: true,
    bettingInterests: [],
    scratchedRunners: [],
    raceBets: [],
    wagerGroup: null,
    myBetsResultedBetTicket: false,
    replaySrc: null,
    sendVideoGtmEvent: noop,
    fullResults: [],
    hasTimeformData: false,
    deviceLockRotationToggle: noop,
    device: "mobile",
    enableRaceResultsReDesign: false,
    seeAllHorsesGTM: noop,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    racePayoffsShift: false,
    currentRace: {},
    mtpNotificationConfig: mtpNotificationConfigDefault,
    handicappingMTPNotification: false,
    isVideoIframe: false,
    disableFullscreen: false,
    isAutoPlay: false,
    isTvg5: false,
    defaultTab: MyBetsPastPerformanceTab.FULL_RESULT
  };

  shouldComponentUpdate(nextProps) {
    const { race, runner, results, bettingInterests, raceBets } = this.props;

    return (
      !isEqual(results, nextProps.results) ||
      !isEqual(race, nextProps.race) ||
      !isEqual(runner, nextProps.runner) ||
      !isEqual(bettingInterests, nextProps.bettingInterests) ||
      !isEqual(raceBets, nextProps.raceBets) ||
      !isEqual(this.props, nextProps)
    );
  }

  renderResults = () => {
    return (
      <RaceOfficials
        isResultsPending={false}
        results={{
          payoffs: get(this.props.results, "payoffs") || [],
          runners: get(this.props.results, "runners") || []
        }}
        firstHandicapBold
        defaultTab={this.props.defaultTab}
        raceTypeCode={get(this.props.race, "raceTypeCode", "")}
        raceNumber={get(this.props.race, "number", "")}
        onTabClick={this.props.onTabClick}
        handicapType="basic"
        bettingInterests={this.props.bettingInterests}
        hasNoHandicappingSelection
        bets={this.props.raceBets}
        wagerGroup={this.props.wagerGroup}
        myBetsResultedBetTicket={this.props.myBetsResultedBetTicket}
        fullResults={this.props.fullResults}
        scratchedRunners={this.props.scratchedRunners}
        runnerName={get(this.props.runner, "horseName")}
        resultsType="full"
        hasTimeformData={this.props.hasTimeformData}
        winningTime={get(this.props.results, "winningTime")}
        enableRaceResultsReDesign={this.props.enableRaceResultsReDesign}
        seeAllHorsesGTM={this.props.seeAllHorsesGTM}
        handicappingRunnerFlags={this.props.handicappingRunnerFlags}
        raceId={get(this.props.race, "id")}
        racePayoffsShift={this.props.racePayoffsShift}
        device={this.props.device}
      />
    );
  };

  renderVideo = () =>
    this.props.replaySrc && this.props.isVideoIframe ? (
      <div>
        <iframe
          title="rcn-player-replay-video"
          src={`${this.props.replaySrc}&autoplay=1`}
          allowFullScreen="true"
          width="100%"
          height="100%"
          allow={`fullscreen${this.props.isAutoPlay ? ";autoplay" : ""}`}
          scrolling="no"
          id="rcn-player-replay-video"
        />
      </div>
    ) : (
      <Video
        raceId={get(this.props.race, "id")}
        src={{ hd: null, sd: null, replay: this.props.replaySrc }}
        isReplay
        inlineVideo
        onVideoPlay={this.props.sendVideoGtmEvent}
        videoFullscreenTilt={this.props.videoFullscreenTilt}
        deviceLockRotationToggle={this.props.deviceLockRotationToggle}
        isAutoPlay={this.props.isAutoPlay}
        disableFullscreen={this.props.disableFullscreen}
      />
    );

  renderVideoFallback = () =>
    get(this.props, "isAccountCompliant", true) ? (
      <Fragment>
        <NoReplayContainer />
        <NoReplayContainerMessage>
          <IconContainer>
            <Icon icon={info} color={buildColor("white", "100")} size={16} />
          </IconContainer>
          <MessageContainer>No replay available</MessageContainer>
        </NoReplayContainerMessage>
      </Fragment>
    ) : (
      <UnavailableVideoContainer data-qa-label="unavailable-video-container">
        <DSIcon
          name="liveVideo"
          size="xl"
          qaLabel="live_video_icon"
          lineColor="--fd-colors-component-button-transparent-content-base"
          backgroundColor="transparent"
        />
        <Paragraph
          fontFamily="regular"
          qaLabel="unavailable-message"
          color="--fd-colors-component-button-transparent-content-base"
          my="space-5"
          textAlign="center"
        >
          {get(
            this.props.watchFDTVMessages,
            "unavailableVideo.notVerified",
            "Verify your account to watch FanDuel TV"
          )}
        </Paragraph>
      </UnavailableVideoContainer>
    );

  renderMTPNotification = () =>
    this.props.handicappingMTPNotification && (
      <MTPNotification
        onModalClose={this.props.onModalClose}
        currentRace={this.props.currentRace}
        mtpNotificationConfig={this.props.mtpNotificationConfig}
      />
    );

  render() {
    if (this.props.isLoading) {
      <ModalWrapper>
        <ContentWrapper withBorders={this.props.withBorders}>
          <LoadingState>
            <FeedbackOverlay
              loadingIconColor={buildColor("grey", "900")}
              overlayBgColor={buildColor("white", "70")}
            />
          </LoadingState>
        </ContentWrapper>
      </ModalWrapper>;
    }

    return (
      <ModalWrapper>
        <ContentWrapper withBorders={this.props.withBorders}>
          <InfoContainer borderless={this.props.withBorders}>
            {this.props.enableRaceResultsReDesign ? (
              <RunnerInfoPastPerformance
                race={this.props.race}
                hasNextRace={this.props.hasNextRace}
                hasPreviousRace={this.props.hasPreviousRace}
                onPreviousRaceNavigation={this.props.onPreviousRaceNavigation}
                onNextRaceNavigation={this.props.onNextRaceNavigation}
                hasNumberOfRunners={this.props.device !== "desktop"}
              />
            ) : (
              <InlinePastPerformanceInfo
                race={this.props.race}
                hasNextRace={this.props.hasNextRace}
                hasPreviousRace={this.props.hasPreviousRace}
                useSimpleLayout
                onPreviousRaceNavigation={this.props.onPreviousRaceNavigation}
                onNextRaceNavigation={this.props.onNextRaceNavigation}
                hasNumberOfRunners={this.props.device !== "desktop"}
              />
            )}
          </InfoContainer>
          <VideoContainer
            isVideoAvailable={
              get(this.props, "race.hasReplays") && this.props.replaySrc
            }
            isPlaying={!!this.props.replaySrc}
            isTvg5={this.props.isTvg5}
            isMobile={this.props.device === "mobile"}
          >
            {get(this.props, "race.hasReplays") &&
            get(this.props, "isAccountCompliant", true)
              ? this.renderVideo()
              : this.renderVideoFallback()}
          </VideoContainer>
          {this.renderResults()}
          {this.renderMTPNotification()}
        </ContentWrapper>
      </ModalWrapper>
    );
  }
}
