import styled, { css } from "styled-components";
import { margin } from "styled-system";

import { TAG_THEME_VARIANTS, TAG_THEME_SIZES } from "./style.themes";

import type { TagProps, TagSize } from "./types";

export const TagContainer = styled.div<
  Pick<TagProps, "variant" | "label" | "size">
>`
  align-items: center;
  align-content: center;
  background-color: var(
    ${({ variant }) =>
      TAG_THEME_VARIANTS[variant as TagProps["variant"]].backgroundColor}
  );
  border-radius: 12px;
  display: flex;
  gap: 4px;
  height: ${({ size }) => TAG_THEME_SIZES[size as TagSize].height};
  justify-content: center;
  white-space: nowrap;
  max-height: ${({ size }) => TAG_THEME_SIZES[size as TagSize].height};
  padding: ${({ size, label }) =>
    label === undefined && size === "large"
      ? TAG_THEME_SIZES[size as TagSize].paddingOnlyIcon
      : TAG_THEME_SIZES[size as TagSize].padding};
  ${margin}
`;

export const TagLabel = styled.span<TagProps>`
  color: var(
    ${({ variant }) => TAG_THEME_VARIANTS[variant as TagProps["variant"]].color}
  );
  font-family: "RobotoCondensed-Regular", sans-serif;
  font-size: ${({ size }) => TAG_THEME_SIZES[size as TagSize].fontSize};
  font-style: normal;
  font-weight: 400;
  max-height: max-content;
  text-transform: uppercase;

  ${({ isEllipsed }) =>
    isEllipsed &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
    `}
`;
