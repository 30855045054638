import { TalentPick } from "@tvg/ts-types/TalentPicks";
import { BetGroupStatus, BetStateType, BetTicketGroupType } from "../types";

export type SetStartBettingAction = {
  type: "SET_START_BETTING";
  payload: boolean;
};

export type SetHasUserSetAmountAction = {
  type: "SET_HAS_USER_SET_AMOUNT";
  payload: boolean;
};

export type SetRaceOfBet = {
  type: "SET_RACE_OF_BET";
  payload: string;
};

export type SetBetAmountAction = {
  type: "SET_BET_AMOUNT";
  payload: number;
};

export type SetSelectionScratchedAction = {
  type: "SET_SELECTION_SCRATCHED";
  payload: string[][];
};

export type SetBetTypeAction = {
  type: "SET_BET_TYPE";
  payload: number | null | undefined;
};

export type SetBetSelectionsAction = {
  type: "SET_BET_SELECTIONS";
  payload: string[][];
};

export type SetBetSelectionsMatrixAction = {
  type: "SET_SELECTIONS_MATRIX";
  payload: { selections: string[][] };
};

export type ResetBetSelectionsAction = {
  type: "RESET_BET_SELECTIONS";
};

export type ResetBetSelectctionsKeepWinAction = {
  type: "RESET_BET_SELECTIONS_KEEP_WIN";
  payload?: number;
};

export type ResetBettingAction = {
  type: "RESET_BETTING";
};

export type SetBetStateAction = {
  type: "SET_BET_STATE";
  payload: BetStateType;
};

export type SetGroupBetTicketStatus = {
  type: "SET_GROUP_BET_TICKET_STATUS";
  payload: BetTicketGroupType;
};

export type SetGroupBetTicketStatusClear = {
  type: "SET_GROUP_BET_TICKET_STATUS_CLEAR";
};

export type SetGroupBetTicketStatusClearAll = {
  type: "SET_GROUP_BET_TICKET_STATUS_CLEAR_ALL";
};

export type SetPickLegAction = {
  type: "SET_PICK_LEG";
  payload: number;
};

export type SetPickAction = {
  type: "SET_PICK";
  payload: {
    value: TalentPick | null | undefined;
  };
};

export type SetGroupBetAddProcessing = {
  type: "SET_GROUP_BET_ADD_PROCESSING";
};

export type SetGroupBetAddSuccess = {
  type: "SET_GROUP_BET_ADD_SUCCESS";
};

export type SetGroupBetAddError = {
  type: "SET_GROUP_BET_ADD_ERROR";
};

export type SetGroupBetRemoveProcessing = {
  type: "SET_GROUP_BET_REMOVE_PROCESSING";
};

export type SetGroupBetRemoveSuccess = {
  type: "SET_GROUP_BET_REMOVE_SUCCESS";
};

export type SetGroupBetRemoveError = {
  type: "SET_GROUP_BET_REMOVE_ERROR";
};

export type SetBetSlipOpen = {
  type: "SET_BET_SLIP_OPEN";
  payload: boolean;
};

export type SetAddToBetSlipOrigin = {
  type: "SET_ADD_TO_BET_SLIP_ORIGIN";
  payload: string;
};

export type SetBetTipHeight = {
  type: "SET_BET_TIP_HEIGHT";
  payload: number;
};

export type Actions =
  | SetStartBettingAction
  | SetBetAmountAction
  | SetSelectionScratchedAction
  | SetBetTypeAction
  | SetBetSelectionsAction
  | SetHasUserSetAmountAction
  | SetBetSelectionsMatrixAction
  | ResetBetSelectionsAction
  | ResetBetSelectctionsKeepWinAction
  | ResetBettingAction
  | SetBetStateAction
  | SetPickLegAction
  | SetPickAction
  | SetRaceOfBet
  | SetGroupBetTicketStatus
  | SetGroupBetTicketStatusClear
  | SetGroupBetTicketStatusClearAll
  | SetGroupBetAddProcessing
  | SetGroupBetAddSuccess
  | SetGroupBetAddError
  | SetGroupBetRemoveProcessing
  | SetGroupBetRemoveSuccess
  | SetGroupBetRemoveError
  | SetBetSlipOpen
  | SetBetTipHeight
  | SetAddToBetSlipOrigin;

export const setStartBetting = (
  value: boolean = true
): SetStartBettingAction => ({
  type: "SET_START_BETTING",
  payload: value
});

export const setHasUserSetAmount = (
  value: boolean
): SetHasUserSetAmountAction => ({
  type: "SET_HAS_USER_SET_AMOUNT",
  payload: value
});

export const setBetAmount = (value: number): SetBetAmountAction => ({
  type: "SET_BET_AMOUNT",
  payload: value
});

export const setSelectionScratched = (
  value: string[][]
): SetSelectionScratchedAction => ({
  type: "SET_SELECTION_SCRATCHED",
  payload: value
});

export const setBetType = (
  value: number | null | undefined
): SetBetTypeAction => ({
  type: "SET_BET_TYPE",
  payload: value
});

export const setRaceOfBet = (value: string): SetRaceOfBet => ({
  type: "SET_RACE_OF_BET",
  payload: value
});

export const setSelectionRequest = (
  selections: string[][]
): SetBetSelectionsAction => ({
  type: "SET_BET_SELECTIONS",
  payload: selections
});

export const setSelectionMatrixRequest = (selections: {
  selections: string[][];
}): SetBetSelectionsMatrixAction => ({
  type: "SET_SELECTIONS_MATRIX",
  payload: selections
});

export const resetBetSelection = (): ResetBetSelectionsAction => ({
  type: "RESET_BET_SELECTIONS"
});

export const resetBetSelectionsKeepWin = (
  columnCount?: number
): ResetBetSelectctionsKeepWinAction => ({
  type: "RESET_BET_SELECTIONS_KEEP_WIN",
  payload: columnCount
});

export const resetBetting = (): ResetBettingAction => ({
  type: "RESET_BETTING"
});

export const setBetState = (value: BetStateType): SetBetStateAction => ({
  type: "SET_BET_STATE",
  payload: value
});

export const setPickLeg = (value: number): SetPickLegAction => ({
  type: "SET_PICK_LEG",
  payload: value
});

export const setBetSlipOpen = (value: boolean): SetBetSlipOpen => ({
  type: "SET_BET_SLIP_OPEN",
  payload: value
});

export const setPickAction = (
  value: TalentPick | null | undefined
): SetPickAction => ({
  type: "SET_PICK",
  payload: {
    value
  }
});

export const setGroupBetTicketStatusAction = (
  betGroupStatus: BetGroupStatus,
  isRepeat?: boolean
): SetGroupBetTicketStatus => ({
  type: "SET_GROUP_BET_TICKET_STATUS",
  payload: {
    betGroupStatus,
    isRepeat
  }
});

export const setGroupBetTicketStatusClearAction = () => ({
  type: "SET_GROUP_BET_TICKET_STATUS_CLEAR"
});

export const setGroupBetTicketStatusClearAllAction = () => ({
  type: "SET_GROUP_BET_TICKET_STATUS_CLEAR_ALL"
});

export const setGroupBetAddProcessingAction = () => ({
  type: "SET_GROUP_BET_ADD_PROCESSING"
});

export const setGroupBetAddSuccessAction = () => ({
  type: "SET_GROUP_BET_ADD_SUCCESS"
});

export const setGroupBetAddErrorAction = () => ({
  type: "SET_GROUP_BET_ADD_ERROR"
});

export const setGroupBetRemoveProcessingAction = () => ({
  type: "SET_GROUP_BET_REMOVE_PROCESSING"
});

export const setGroupBetRemoveSuccessAction = () => ({
  type: "SET_GROUP_BET_REMOVE_SUCCESS"
});

export const setGroupBetRemoveErrorAction = () => ({
  type: "SET_GROUP_BET_REMOVE_ERROR"
});

export const setAddToBetSlipOriginAction = (origin: string) => ({
  type: "SET_ADD_TO_BET_SLIP_ORIGIN",
  payload: origin
});
