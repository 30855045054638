import mediator from "@tvg/mediator";
import type { UserJurisdiction } from "@tvg/api/aw/types";
import { LoginModalMediatorEventType } from "@tvg/ts-types/Amplitude";

import type { UserInfo } from "../../../types/account/user";

// All of those functions have weird name, but I can't identify if we can change and If is really necessary,
// so I'll keep, but if in future you know, please change that.

export const loginSuccessGtm = (accountId: string, userBalance?: number) => {
  mediator.base.dispatch({
    type: "FDR_LOGIN_SUCCESS",
    payload: {
      accountId,
      userBalance
    }
  });
};

export const openLoginSuccessGtm = (
  user: UserInfo,
  jurisdiction: UserJurisdiction | null,
  isVerified: boolean
) => {
  mediator.base.dispatch({
    type: LoginModalMediatorEventType.LOGIN_SERVICE_LOADED,
    payload: { user, jurisdiction, isVerified }
  });
};
