export const defaultSize = "14px";
export const titleFont = "'Roboto Condensed', sans-serif";
export const titleFontSecondary = "'Roboto', sans-serif";
export const titleMedium = "'Roboto-Medium', sans-serif";
export const defaultTextFont = "'Roboto', Arial, sans-serif";

// New fonts
export const fontCondensedNormal = "'RobotoCondensed-Regular', sans-serif";
export const fontNormal = "'Roboto-Regular', sans-serif";
export const fontMono = "'Roboto Mono', monospace";
export const fontMedium = "'Roboto-Medium', sans-serif";
export const fontBold = "'Roboto-Bold', sans-serif";
export const fontBlack = "'Roboto-Black', sans-serif";
export const fontSansSerif = "sans-serif";
