import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getXsellKeys } from "../../utils";
import { ENV_KEYS } from "../../constants";

export const EXPERIMENT_KEY =
  "qfd-1033-allow-certain-users-to-chose-hp-built-in-profiles";

export const DEPLOYMENT_KEY = isXSell() ? getXsellKeys() : ENV_KEYS.fdr_fe.web;

export const DEFAULT_PAYLOAD = false;
