export interface Payload<T = string, P = unknown> {
  type: T;
  payload?: P;
}

export enum UMEventsGeneric {
  HotReload = "HOT_RELOAD"
}

export enum UMEventsAccount {
  Session = "GET_SESSION",
  Join = "TRIGGER_JOIN",
  Logout = "TRIGGER_LOGOUT",
  OpenSettings = "OPEN_USER_SETTINGS",
  PresentModal = "PRESENT_MODAL",
  AcceptTerms = "TRIGGER_ACCEPT_TERMS",
  Verification = "TRIGGER_VERIFICATION",
  KeepAlive = "KEEP_ALIVE",
  SessionUpdate = "SESSION_UPDATE",
  FirstLoad = "FIRST_LOAD"
}

export enum UMEventsWallet {
  GetBalance = "GET_BALANCE",
  Deposit = "DEPOSIT",
  Tax = "TAX",
  Transactions = "TRANSACTIONS",
  Withdraw = "WITHDRAW",
  Activity = "ACTIVITY",
  AccountHome = "ACCOUNT_HOME"
}

export enum UMEventsAmplitude {
  SetDeviceId = "SET_DEVICE_ID",
  SetSessionId = "SET_SESSION_ID"
}

export const UMEvents = {
  ...UMEventsAccount,
  ...UMEventsWallet,
  ...UMEventsAmplitude,
  ...UMEventsGeneric
};
