import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { DEPLOYMENT_KEY, EXPERIMENT_KEY } from "./constants";
import useExperiments from "../../useExperiments";

type Variant = {
  shouldEnablePPOnXSell: boolean;
};

export const useURPProgramPageOnXSell = () => {
  const { getVariant } = useExperiments(DEPLOYMENT_KEY);
  const variant = getVariant<unknown, Variant>(EXPERIMENT_KEY);

  return isXSell() && !!variant?.payload?.shouldEnablePPOnXSell;
};
