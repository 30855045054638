import styled from "styled-components/native";
import { View } from "react-native";
import { layout } from "styled-system";
import { colors } from "../../../theming/themes/common/colors";
import { Paragraph } from "../../typography";

export const StyledComponent = styled(View)<{ isDesktop: boolean }>`
  justify-content: center;
  padding-left: ${({ theme }) => theme.space["space-4"]};
  background-color: ${colors.white["900"]};
  box-shadow: 0 1px 0 ${colors.blue["100"]};
  min-height: ${({ isDesktop }) => (isDesktop ? "64px" : "48px")};
  ${layout}
`;

export const TextContainer = styled(View)`
  flex-shrink: 1;
`;

export const Subtitle = styled(Paragraph)`
  color: var(--fd-colors-content-subtle);
  line-height: 125%;
  margin-top: var(--fd-space-space-05, 2px);
`;
