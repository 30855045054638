import React, { useMemo } from "react";
import { pluralize } from "@tvg/utils/generalUtils";
import BeatenDistanceText from "./styled-components";

const computeBeatenDistance = (
  shouldComputeBeatenDistanceStatus,
  beatenDistance,
  beatenDistanceStatus
) => {
  if (shouldComputeBeatenDistanceStatus) {
    if (beatenDistance < 0.5) return beatenDistanceStatus;
    return `${beatenDistanceStatus} ${pluralize(
      beatenDistance,
      "length",
      false
    )}`;
  }
  return beatenDistanceStatus;
};

const BeatenDistance = (props) => {
  const {
    isWinner,
    beatenDistance,
    beatenDistanceStatus,
    shouldComputeBeatenDistanceStatus
  } = props;

  return useMemo(() => {
    if (!beatenDistance || !beatenDistanceStatus) return null;
    let beatenDistancePre = parseFloat(beatenDistance) < 0.5 ? "by a " : "by ";
    if (isWinner) {
      beatenDistancePre = `won ${beatenDistancePre}`;
    }

    const computedBeatenDistanceAfter = computeBeatenDistance(
      shouldComputeBeatenDistanceStatus,
      parseFloat(beatenDistance),
      beatenDistanceStatus
    );

    return (
      <BeatenDistanceText>
        {beatenDistancePre + computedBeatenDistanceAfter.toLowerCase()}
      </BeatenDistanceText>
    );
  }, [beatenDistance, beatenDistanceStatus, shouldComputeBeatenDistanceStatus]);
};

BeatenDistance.defaultProps = {
  isWinner: false,
  beatenDistance: "",
  beatenDistanceStatus: "",
  shouldComputeBeatenDistanceStatus: false
};

export default BeatenDistance;
