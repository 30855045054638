/**
 * BaseEventEmitter
 *
 * Pure JavaScript EventEmitter. This is used as base to keep track of dispatch
 * events. Other event emitters can then extend this and just care about their
 * implementation details
 */

import { get as lodashGet } from "lodash";
import { get, set } from "lodash/fp";
import tvgConf from "@tvg/conf";
import type {
  EventEmitterInterface,
  EventName,
  EventListener,
  FSA
} from "../Types";
import { isBrowser } from "../MediatorUtils";

const MEDIATOR_LOG_EVENTS_COOKIE = "useMediatorLogEvents";

type EventHandler = {
  payload?: FSA;
  subscribers: Array<EventListener>;
};

class BaseEventEmitter implements EventEmitterInterface {
  events: {
    [key in EventName]: EventHandler;
  };

  constructor() {
    this.events = {};
  }

  dispatch(payload: FSA) {
    if (
      lodashGet(tvgConf(), "environment") === "qa" &&
      typeof window !== "undefined" &&
      document.cookie.includes(`${MEDIATOR_LOG_EVENTS_COOKIE}=true`)
    ) {
      console.log("mediator.dispatch - payload:", payload);
    }

    const event = get(payload.type, this.events);
    if (event) {
      event.payload = payload;
      if (
        isBrowser() &&
        lodashGet(window, "rnEvents", []).indexOf(payload.type) === -1
      ) {
        event.subscribers.forEach((subscriber) => {
          if (subscriber) {
            subscriber(payload);
          }
        });
      } else if (typeof window !== "undefined") {
        // @ts-ignore
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            type: payload.type,
            body: payload.payload
          })
        );
      }
      return this;
    }

    this.events = set(
      payload.type,
      {
        payload,
        subscribers: []
      },
      this.events
    );
    return this;
  }

  subscribe(name: EventName, subscriber: EventListener) {
    const event = get(name, this.events);

    if (!event) {
      this.events = set(
        name,
        {
          payload: null,
          subscribers: [subscriber]
        },
        this.events
      );
    } else {
      event.subscribers = event.subscribers.concat(subscriber);
    }

    // Not proud of this but I need it
    const self = this;

    return {
      replay() {
        if (event?.payload) {
          subscriber(event.payload);
        }
        return this;
      },

      unsubscribe() {
        self.unsubscribe(name, subscriber);
        return this;
      }
    };
  }

  unsubscribe(name: EventName, subscriber: EventListener) {
    const event = get(name, this.events);

    if (event) {
      event.subscribers = event.subscribers.filter((fn) => fn !== subscriber);
    }

    return this;
  }
}

export default BaseEventEmitter;
