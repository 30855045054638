import styled, { css } from "styled-components/native";
import { View } from "react-native";
import { Header, Paragraph } from "../../typography";
import { colors } from "../../../theming/themes/common/colors";

export const StyledComponent = styled(View)<{
  hasRoundedCorners: boolean;
  hasBorderBottom?: boolean;
  hasShadow: boolean;
  hasSubtitle: boolean;
  isMobile: boolean;
  isDarkMode?: boolean;
}>`
  flex-direction: row;
  align-content: space-between;
  padding: ${({ isMobile, theme }) =>
    isMobile ? theme.space["space-3"] : "14px"};
  background-color: ${({ theme, isDarkMode }) =>
    isDarkMode
      ? theme.colorTokens.background.promotional
      : "var(--fd-colors-background-surface)"};
  border-bottom-color: var(--fd-colors-border-subtle);
  ${({ hasBorderBottom }) =>
    hasBorderBottom ? "border-bottom-width: 1px;" : "border-bottom-width: 0px;"}
  ${({ hasShadow }) =>
    hasShadow && `box-shadow: 0 1px 3px ${colors.blue["100"]}`};
  ${({ hasSubtitle, isMobile }) =>
    isMobile
      ? `min-height: ${hasSubtitle ? "69px" : "48px"}`
      : `min-height: ${hasSubtitle ? "85px" : "64px"}`};
  ${({ hasRoundedCorners }) =>
    hasRoundedCorners &&
    "border-top-left-radius: var(--fd-radii-border-radius-020); border-top-right-radius: var(--fd-radii-border-radius-020);"}
`;

export const StyledHeader = styled(Header)`
  font-size: var(--fd-font-sizes-font-size-060);
  line-height: var(--fd-line-heights-line-height-010);
  ${({ isDarkMode }) =>
    isDarkMode
      ? "color: var(--fd-colors-content-on-dark)"
      : "color: var(--fd-colors-content-strong)"};
`;

const trackModalleftPadding = (isTrackRulesModal?: boolean) =>
  isTrackRulesModal ? "4px" : "0";

export const TextContainer = styled(View)<{
  isMobile: boolean;
  isPaddingLeft: boolean;
  isPaddingRight: boolean;
  withoutButton: boolean;
  titlePosition?: string;
  isTrackRulesModal?: boolean;
}>`
  flex-direction: column;
  ${({ titlePosition }) =>
    titlePosition &&
    css`
      align-items: ${titlePosition};
    `};
  justify-content: center;
  flex-grow: 1;
  flex-shrink: 1;
  padding: ${({
    isMobile,
    theme,
    isPaddingLeft,
    isPaddingRight,
    isTrackRulesModal
  }) =>
    isMobile
      ? `${theme.space["space-1"]}
      ${isPaddingRight ? theme.space["space-8"] : "0"}
      ${theme.space["space-1"]}
      ${
        isPaddingLeft && !isTrackRulesModal
          ? theme.space["space-8"]
          : trackModalleftPadding(isTrackRulesModal)
      }`
      : `${theme.space["space-2"]}
         ${isPaddingRight ? theme.space["space-8"] : "0"}
         ${theme.space["space-2"]}
         ${isPaddingLeft ? theme.space["space-8"] : "0"}`};
  ${({ withoutButton, isMobile, theme }) =>
    withoutButton &&
    `padding: ${isMobile ? theme.space["space-1"] : theme.space["space-2"]}`};
`;

export const DescriptionContainer = styled(View)`
  flex-direction: row;
  align-items: center;
  padding-top: ${({ theme }) => theme.space["space-1"]};
`;

export const ButtonIconContainer = styled(View)`
  padding: 2px 0;
`;

export const ContainerLink = styled(View)`
  flex-shrink: 1;
`;

export const StyledParagraph = styled(Paragraph)`
  text-align: center;
`;

export const LeftElement = styled(View)`
  justify-content: center;
`;
