import React from "react";
import { noop } from "lodash";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";
import { Link, useNavigate, NavigateFunction } from "react-router-dom";
import formatCurrency from "@tvg/formatter/currency";
import { useTheme } from "@tvg/design-system";
import { Button } from "@tvg/design-system/web";
import { resetBetting } from "@tvg/desktop-bet/src/store/actions";
import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { isFdr } from "@tvg/pp/src/utils/general";
// @ts-ignore
import { showVideo } from "@fdr/shared-actions/video";
import {
  RaceHeaderContainer,
  RaceHeaderWrapper,
  RaceWatchReplayWrapper,
  RaceHeaderInfo,
  RaceTrackNameWrapper,
  RaceTrackName,
  RaceTrackNumber,
  RaceFeaturedEventName,
  RaceBetsInfo,
  RaceHeaderActions
} from "./styled-components";
import { Props, ButtonPropsType } from "./types";
import HeaderMTP from "./mtp";

const getBetsInfo = (betAmount: number, bets: number) => {
  const betTitle = `Bet${bets > 1 ? "s" : ""}`;
  return `${bets} ${betTitle} • ${formatCurrency(betAmount)} staked`;
};

const getButtonProps = (hasReplayVideo: boolean): ButtonPropsType =>
  hasReplayVideo
    ? {
        text: "Watch Replay",
        type: "primary",
        hasIcon: true,
        hasShadow: false
      }
    : {
        text: "See Result",
        type: "secondary",
        hasIcon: false,
        hasShadow: true
      };

const renderVideoButton = (
  raceUrl: string,
  currentRaceNumber: number,
  showRaceNumber: boolean,
  isRaceNear: boolean,
  onVideoRedirect: () => void,
  dispatch: Dispatch,
  navigate: NavigateFunction
) => (
  <Button
    qaLabel="watch-video-button"
    variant={isRaceNear ? "primary" : "secondary"}
    icon="video"
    onClick={(e) => {
      const [racePathname] = raceUrl.split("?");
      navigate(
        {
          pathname: racePathname,
          search: `?race=${currentRaceNumber}`
        },
        { state: { showVideo: isFdr() } }
      );

      onVideoRedirect();
      dispatch(resetBetting());
      if (isFdr()) {
        dispatch(showVideo());
      }
      e.preventDefault();
      e.stopPropagation();
    }}
  >
    {` Watch ${showRaceNumber ? `R${currentRaceNumber}` : "Live"}`}
  </Button>
);

const renderRedirectReplayButton = (
  raceUrl: string,
  hasReplayVideo: boolean,
  onWatchReplay: (boolean: boolean) => void,
  closeModal: () => void,
  dispatch: Dispatch,
  navigate: NavigateFunction
) => {
  const theme = useTheme();
  const buttonProps = getButtonProps(hasReplayVideo);
  return (
    <RaceWatchReplayWrapper theme={theme}>
      <Button
        qaLabel="watch-video-button"
        variant={buttonProps.type}
        icon={buttonProps.hasIcon ? "video" : undefined}
        onClick={(e) => {
          navigate(raceUrl);
          onWatchReplay(false);
          closeModal();
          dispatch(resetBetting());
          if (isFdr()) {
            dispatch(showVideo());
          }
          e.preventDefault();
          e.stopPropagation();
        }}
        isStretched={true}
      >
        {buttonProps.text}
      </Button>
    </RaceWatchReplayWrapper>
  );
};

const renderReplayButton = (
  onWatchReplay: () => void,
  isReplayInsideMyBets: boolean,
  hasReplayVideo: boolean,
  raceUrl: string,
  showSeeResult: boolean,
  closeModal: () => void,
  dispatch: Dispatch,
  navigate: NavigateFunction
  // eslint-disable-next-line consistent-return
) => {
  const theme = useTheme();

  if (!isReplayInsideMyBets && hasReplayVideo) {
    return renderRedirectReplayButton(
      raceUrl,
      hasReplayVideo,
      onWatchReplay,
      closeModal,
      dispatch,
      navigate
    );
  }
  if (showSeeResult || isReplayInsideMyBets) {
    const buttonProps = getButtonProps(hasReplayVideo);
    return (
      <RaceWatchReplayWrapper theme={theme}>
        <Button
          variant={buttonProps.type}
          icon={buttonProps.hasIcon ? "replay" : undefined}
          onClick={onWatchReplay}
          qaLabel="watch-video-button"
          isStretched={true}
        >
          {buttonProps.text}
        </Button>
      </RaceWatchReplayWrapper>
    );
  }
};

const MyBetsCardHeader = ({
  racePostTime = "2020-11-04T17:00:00Z",
  raceDate,
  statusCode = RaceStatusEnum.OPEN,
  trackName = "",
  eventName = "",
  raceNumber = 1,
  mtp = 60,
  bets = 0,
  betAmount = 0,
  dateDiff = 0,
  raceUrl = "",
  closeModal = noop,
  onWatchReplay = noop,
  hasReplayVideo = false,
  hasLiveVideo = false,
  showRaceLeg = false,
  legNumber = 1,
  onVideoRedirect = noop,
  isReplayInsideMyBets = true,
  showBetInfo = false,
  qaLabel = "mybets-card-header",
  hasPastPerformance = true,
  isRaceNear = false,
  currentRaceNumber = 1,
  showSeeResult = false,
  isMyBetsOpen = false,
  hasRepeatBet = false,
  onRepeatBet = noop,
  isFutureBet = false,
  isLoadingGraphs = false
}: Props) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const roundDiff = Math.round(dateDiff);

  const shouldRedirectToProgramPage = () => {
    if (roundDiff === 0 && isMyBetsOpen && !isFutureBet) {
      return true;
    }
    return roundDiff === 1 && dateDiff < 1 && isMyBetsOpen && !isFutureBet;
  };

  return (
    <RaceHeaderContainer data-qa-label={qaLabel}>
      <RaceHeaderWrapper
        data-qa-label={`${qaLabel}-details`}
        as={shouldRedirectToProgramPage() ? Link : "div"}
        to={raceUrl}
        onClick={shouldRedirectToProgramPage() ? closeModal : () => {}}
      >
        <RaceHeaderInfo data-qa-label={`${qaLabel}-info`} theme={theme}>
          {!isLoadingGraphs && (
            <HeaderMTP
              qaLabel={qaLabel}
              racePostTime={racePostTime}
              legNumber={legNumber}
              raceDate={raceDate}
              statusCode={statusCode}
              mtp={mtp}
              showRaceLeg={showRaceLeg}
              isFutureBet={isFutureBet}
              dateDiff={roundDiff}
            />
          )}
          {eventName && (
            <RaceFeaturedEventName
              data-qa-label={`${qaLabel}-event-name`}
              theme={theme}
            >
              {eventName}
            </RaceFeaturedEventName>
          )}
          <RaceTrackNameWrapper
            data-qa-label={`${qaLabel}-track-name`}
            theme={theme}
          >
            <RaceTrackName>{trackName}</RaceTrackName>
            {raceNumber && (
              <RaceTrackNumber>{` R${raceNumber}`}</RaceTrackNumber>
            )}
          </RaceTrackNameWrapper>
          {showBetInfo && (
            <RaceBetsInfo data-qa-label={`${qaLabel}-bets-info`} theme={theme}>
              {getBetsInfo(betAmount, bets)}
            </RaceBetsInfo>
          )}
        </RaceHeaderInfo>
        <RaceHeaderActions
          data-qa-label={`${qaLabel}-header-actions`}
          theme={theme}
        >
          {hasLiveVideo &&
            renderVideoButton(
              raceUrl,
              currentRaceNumber,
              !!legNumber,
              isRaceNear,
              onVideoRedirect,
              dispatch,
              navigate
            )}
          {hasRepeatBet && (
            <Button
              onClick={onRepeatBet}
              qaLabel="repeat-bet-link"
              variant="tertiary"
              size="l"
            >
              Repeat bet
            </Button>
          )}
        </RaceHeaderActions>
      </RaceHeaderWrapper>
      {hasPastPerformance &&
        renderReplayButton(
          onWatchReplay,
          isReplayInsideMyBets,
          hasReplayVideo,
          raceUrl,
          showSeeResult,
          closeModal,
          dispatch,
          navigate
        )}
    </RaceHeaderContainer>
  );
};

export default MyBetsCardHeader;
