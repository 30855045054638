import React, { useContext } from "react";
import { useTheme } from "../../../theming";
import { TvgConfContext } from "../../../utils/tvgConfProvider";
import { useQaLabel } from "../../../hooks/useQaLabel";
import { Icon } from "../../icon";
import { IconButton } from "../../iconButton";
import {
  DescriptionContainer,
  StyledComponent,
  StyledHeader,
  TextContainer,
  ButtonIconContainer,
  ContainerLink,
  StyledParagraph,
  LeftElement
} from "./styled-components";
import { ModalHeaderProps, PagesHeaderProps } from "./types";
import { Link } from "../../link";

export const BaseHeader = ({
  qaLabel = "modal-label",
  title,
  subtitle = "",
  subtitleType = "description",
  hasRoundedCorners = false,
  hasShadow = false,
  linkUrl,
  onBack,
  onClose,
  headerLeftElement,
  isDarkMode = false,
  titlePosition,
  isTrackRulesModal,
  hasBorderBottom = false,
  ...rest
}: PagesHeaderProps & ModalHeaderProps) => {
  const viewTestProps = useQaLabel(qaLabel);
  const { device } = useContext(TvgConfContext);
  const isMobile = device === "mobile";
  const { fonts } = useTheme();

  return (
    <StyledComponent
      hasRoundedCorners={hasRoundedCorners}
      hasSubtitle={!!subtitle}
      hasShadow={hasShadow}
      isMobile={isMobile}
      isDarkMode={isDarkMode}
      hasBorderBottom={hasBorderBottom}
      {...viewTestProps}
      {...rest}
    >
      {React.isValidElement(headerLeftElement) && (
        <LeftElement>{headerLeftElement}</LeftElement>
      )}
      {onBack && (
        <ButtonIconContainer>
          <IconButton
            qaLabel="back-button-pages"
            iconName="arrowLeft"
            size="s"
            variant="tertiary"
            onPress={onBack}
          />
        </ButtonIconContainer>
      )}
      <TextContainer
        isMobile={isMobile}
        isPaddingLeft={!onBack && !!onClose && !headerLeftElement}
        isPaddingRight={!!onBack && !onClose}
        withoutButton={!onClose && !onBack}
        titlePosition={titlePosition}
        isTrackRulesModal={isTrackRulesModal}
      >
        {typeof title === "string" && (
          <StyledHeader
            isDarkMode={isDarkMode}
            tag="h2"
            qaLabel="pages-header"
            fontFamily="bold"
          >
            {title}
          </StyledHeader>
        )}
        {React.isValidElement(title) && <>{title}</>}
        {subtitle !== "" && (
          <DescriptionContainer>
            {subtitleType === "link" && (
              <>
                <Icon
                  name="info"
                  size="s"
                  pr="space-1"
                  qaLabel="description-icon"
                  lineColor="var(--fd-colors-system-info-content-accent)"
                  backgroundColor="var(--fd-colors-system-info-background-subtle)"
                />
                <ContainerLink>
                  <Link
                    qaLabel="link"
                    href={linkUrl}
                    // eslint-disable-next-line react-native/no-inline-styles, react-native/no-color-literals
                    style={{
                      color: "var(--fd-colors-link-default-base)",
                      fontFamily: fonts.regular,
                      textAlign: "center"
                    }}
                  >
                    {subtitle}
                  </Link>
                </ContainerLink>
              </>
            )}
            {subtitleType === "description" && (
              <StyledParagraph
                qaLabel="pages-paragraph"
                color="var(--fd-colors-content-subtle)"
              >
                {subtitle}
              </StyledParagraph>
            )}
            {subtitleType === "element" && React.isValidElement(subtitle) && (
              <>{subtitle}</>
            )}
          </DescriptionContainer>
        )}
      </TextContainer>
      {onClose && (
        <ButtonIconContainer>
          <IconButton
            qaLabel="close-button-pages"
            iconName="close"
            size="s"
            variant={isDarkMode ? "tertiary_dark" : "tertiary"}
            onPress={onClose}
          />
        </ButtonIconContainer>
      )}
    </StyledComponent>
  );
};

export { PagesHeaderProps, ModalHeaderProps };
export default BaseHeader;
