import mediator from "@tvg/mediator";
import decodeJWT from "jwt-decode";
import { BinaryFn } from "@tvg/ts-types/Functional";
import { get } from "lodash";
import {
  GeolocationFailureError,
  GeolocationRejectedError
} from "@tvg/sh-geolocation/src/types";

import { GeoLocationResponseSuccess } from "./types/GeoLocation";
import { GeolocationVerified, UpdatedToken } from "./types/generalTypes";
import { getAuthTokenCookie, isSessionExpired } from "./sessionUtils";

const openExternalLink = (uri: string) => {
  mediator.ios.dispatch({
    type: "OPEN_EXTERNAL_APP",
    payload: { openExternalApp: uri }
  });
};

const askLocation = () => {
  mediator.ios.dispatch({ type: "ASK_LOCATION" });
};

const onTriggerGeolocation = (reason: string) => {
  mediator.ios.dispatch({
    type: "TRIGGER_GEOLOCATION",
    payload: {
      reason
    }
  });
};

const subscribeGeolocationRequest = (callback: Function) => {
  mediator.ios.subscribe("GEOLOCATION_IN_PROGRESS", () => {
    callback();
  });
};

const subscribeLocationFailure = (callback: Function) => {
  mediator.ios.subscribe(
    "LOCATION_FAILURE",
    (data: { payload: GeolocationFailureError }) => {
      callback(data.payload?.geoErrorName);
    }
  );
};

const subscribeLocationRejected = (callback: Function) => {
  mediator.ios.subscribe(
    "GEOLOCATION_REJECTED",
    (data: { payload: GeolocationRejectedError }) => {
      const troubleshooters = data.payload?.troubleshooters;
      const region = data.payload?.region;
      callback(troubleshooters, region);
    }
  );
};

const subscribeGeolocationUpdate = (callback: Function) => {
  mediator.ios.subscribe(
    "GEOLOCATION_TOKEN_UPDATED",
    (data: { payload: UpdatedToken }) => {
      const token = data.payload.geolocationData?.geoToken;
      return callback(token);
    }
  );
};

const subscribeLaunchInRegion = (
  callback: BinaryFn<string | undefined, string, void>
) => {
  mediator.ios.subscribe("LAUNCH_IN_REGION", callback);
};

const subscribeGeolocationVerified = (callback: Function) => {
  mediator.ios.subscribe(
    "GEOLOCATION_PASSED",
    (data: { payload: GeolocationVerified }) => {
      try {
        const { region_code }: GeoLocationResponseSuccess = decodeJWT(
          get(data, "payload.geoToken", "")
        );
        const token = data.payload?.geoToken;
        callback(token, region_code);
      } catch (error) {
        console.error("Error decompile geocomply: ", error);
      }
    }
  );
};

const subscribeWebViewForeground = () => {
  document.addEventListener("visibilitychange", () => {
    if (document.visibilityState === "visible") {
      const authToken = getAuthTokenCookie();
      if (isSessionExpired(authToken)) {
        mediator.base.dispatch({ type: "TRIGGER_LOGOUT" });
      }
    }
  });
};

export default {
  openExternalLink,
  askLocation,
  onTriggerGeolocation,
  subscribeGeolocationRequest,
  subscribeGeolocationVerified,
  subscribeGeolocationUpdate,
  subscribeLocationFailure,
  subscribeLocationRejected,
  subscribeLaunchInRegion,
  subscribeWebViewForeground
};
