import { getDeviceId } from "@amplitude/analytics-browser";
import { Variant } from "@amplitude/experiment-js-client";
import { isAndroid, isIos, isXSell } from "@tvg/sh-utils/mobileUtils";
import { getFdUserId } from "@urp/store-selectors";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ENV_KEYS } from "./constants";
import { VariantEnum } from "./types";
import useExperiments from "./useExperiments";

export const getXsellKeys = () => {
  if (isIos()) return ENV_KEYS.sbk.ios;
  if (isAndroid()) return ENV_KEYS.sbk.android;

  return ENV_KEYS.sbk.web;
};
export const DEPLOYMENT_KEY = isXSell() ? getXsellKeys() : ENV_KEYS.fdr_fe.web;

export const useIsTreatmentVariant = (experimentKey: string) => {
  const { getVariant } = useExperiments(DEPLOYMENT_KEY);
  const variant = getVariant<VariantEnum, boolean>(experimentKey);

  return variant?.value === VariantEnum.TREATMENT;
};

export const useIsTreatmentVariantByUser = (experimentKey: string) => {
  const { setUserExperiment, getVariant } = useExperiments(DEPLOYMENT_KEY);
  const [variant, setVariant] = useState<Variant>();

  const accountNumber = useSelector(getFdUserId);
  const deviceId = getDeviceId();

  useEffect(() => {
    setUserExperiment({ user_id: accountNumber, device_id: deviceId }).then(
      () => {
        setVariant(getVariant<VariantEnum, number>(experimentKey));
      }
    );
  }, [accountNumber, deviceId]);

  return variant?.value === VariantEnum.TREATMENT;
};
