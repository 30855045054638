import { RaceProgram, RaceStatusEnum, RaceWagerType } from "@tvg/ts-types/Race";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { BetBasicInfo, BetRepeatInfo } from "@tvg/ts-types/Bet";
import { get } from "lodash";
import BetSelectionUtils from "@tvg/utils/betSelectionUtils";
import { isHongKongRaces } from "./general";

export const filterHKRaces = (currentRace: RaceProgram) =>
  isHongKongRaces(currentRace.track?.trackCode || "")
    ? BetSelectionUtils.filterHKCoupledEntries(
        get(currentRace, "bettingInterests", [])
      )
    : currentRace.bettingInterests;

export const isRaceoff = (race: RaceProgram) =>
  get(race, "status.code", "") === RaceStatusEnum.RACE_OFF;

export const isWagerable = (race: RaceProgram, isScrollableRaceCard: boolean) =>
  get(race, "wagerable", false) &&
  (get(race, "wagerTypes", []).length > 0 || isScrollableRaceCard);

export const isRefunded = (race: RaceProgram) =>
  !!race.results && !get(race, "results.runners");

export const getSelectionsRunnerNbr = (
  wager: BetBasicInfo | BetRepeatInfo
): Array<Array<string>> =>
  (wager.selections?.selection || []).map((selection) =>
    selection.runners.map((runner) => runner.number.toString())
  );

export const formattedScrollableWagerType = (legCount: number = 0) =>
  ({
    columnCount: 1,
    specialGroup: false,
    unitedWagerTypeCode: "WIN",
    type: { id: 10, name: "Win", code: "WN" as WagerTypeCodesEnum },
    isBox: false,
    isKey: false,
    isWheel: false,
    group: { id: 10, name: "Win", code: "WN" as WagerTypeCodesEnum },
    legCount,
    minWagerAmount: 0,
    wagerAmounts: [],
    positionCount: 0,
    types: []
  }) as RaceWagerType;
