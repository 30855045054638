// todo: replace RaceResultsRunner with this
// legacy component: RaceResultsRunner
// feature toggle: enableRaceResultsReDesign

import React, { useMemo } from "react";

import { formatCurrency, formatSequencial } from "@tvg/formatter";
import { get } from "lodash";
import RunnerNumber from "../../_atom/RunnerNumber";
import BeatenDistance from "../../_atom/BeatenDistance";
import {
  RunnerRow,
  ResultRow,
  RunnerInfo,
  Number,
  RunnerName,
  Position,
  MoneyWon,
  Comment,
  Info,
  InfoItem,
  DNF
} from "./styled-components";

const computeMoneyWon = (runner, betType) => {
  const moneyWon = get(runner, betType, "");
  return moneyWon > 0 ? formatCurrency(moneyWon) : "";
};

const RacePayoffRunner = (props) => {
  const {
    runner,
    bettingInterest,
    raceTypeCode,
    isActive,
    hasDashedBorder,
    gridStartPosition,
    racePayoffsShift
  } = props;

  return useMemo(() => {
    const moneyWon = {
      winPayoff: computeMoneyWon(runner, "winPayoff"),
      placePayoff: computeMoneyWon(runner, "placePayoff"),
      showPayoff: computeMoneyWon(runner, "showPayoff")
    };
    const runnerComment = get(runner, "timeform.postRaceReport");
    const jockey =
      get(bettingInterest, "jockey") ||
      get(bettingInterest, "handicapping[0].value", "") ||
      get(bettingInterest, "handicapping[0]", "");

    const hasJockey =
      typeof jockey === "string" && jockey !== "-" && jockey !== "";
    const trainer =
      get(bettingInterest, "trainer") ||
      get(bettingInterest, "handicapping[1].value", "") ||
      get(bettingInterest, "handicapping[1]", "");
    const hasTrainer =
      typeof trainer === "string" && trainer !== "-" && trainer !== "";
    const isDnf = runner.finishPosition === 99;

    const isWinner = runner.finishPosition === 1;

    return (
      <RunnerRow
        key={`position-runner-${runner.finishPosition}`}
        data-qa-label="raceResultsRunner-runner"
        isActive={isActive}
        hasDashedBorder={hasDashedBorder}
      >
        <ResultRow>
          {isDnf ? (
            <DNF />
          ) : (
            <Position isFirst={runner.finishPosition === 1}>
              {formatSequencial(runner.finishPosition)}
              <BeatenDistance
                isWinner={isWinner}
                beatenDistance={
                  isWinner
                    ? get(runner, "timeform.winningDistance", "")
                    : get(runner, "timeform.accBeatenDistance", "")
                }
                beatenDistanceStatus={
                  isWinner
                    ? get(runner, "timeform.winningDistanceStatus", "")
                    : get(runner, "timeform.accBeatenDistanceStatus")
                }
              />
            </Position>
          )}
          <MoneyWon gridPosition={gridStartPosition}>
            {moneyWon.winPayoff}
          </MoneyWon>

          {(moneyWon.placePayoff || !racePayoffsShift) && (
            <MoneyWon>{moneyWon.placePayoff}</MoneyWon>
          )}
          {moneyWon.showPayoff && <MoneyWon>{moneyWon.showPayoff}</MoneyWon>}
        </ResultRow>
        <RunnerInfo>
          <Number>
            <RunnerNumber
              runnerId={runner.runnerNumber}
              raceTypeCode={raceTypeCode}
            />
          </Number>
          <RunnerName>{get(runner, "runnerName", "")}</RunnerName>
        </RunnerInfo>

        {(hasJockey || hasTrainer) && (
          <Info>
            {hasJockey && <InfoItem>{jockey}</InfoItem>}
            {hasTrainer && <InfoItem> {trainer}</InfoItem>}
          </Info>
        )}

        {runnerComment && <Comment>{runnerComment}</Comment>}
      </RunnerRow>
    );
  }, [
    JSON.stringify(runner),
    JSON.stringify(bettingInterest),
    raceTypeCode,
    isActive,
    hasDashedBorder
  ]);
};

RacePayoffRunner.defaultProps = {
  runner: {
    biNumber: 0,
    runnerNumber: "",
    betAmount: 0,
    finishPosition: 0,
    placePayoff: 0,
    runnerName: "",
    showPayoff: 0,
    winPayoff: 0,
    timeform: null
  },
  raceTypeCode: "T",
  isActive: false,
  hasDashedBorder: false,
  gridStartPosition: false,
  racePayoffsShift: false
};

export default RacePayoffRunner;
