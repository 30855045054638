import styled from "styled-components";

export const OpenButtonWrapper = styled.div`
  button {
    border: 1px solid var(--fd-colors-component-button-tertiary-border-base);
  }
`;

export const SelectorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  max-height: 318px;
  overflow-y: auto;
  background-color: var(--fd-colors-background-surface);
  border-radius: 2px;
  z-index: 1;
`;

export const SelectorContainer = styled.div`
  position: relative;
`;

export const SelectorHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 54px;
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  background-color: var(--fd-colors-background-surface);
  z-index: 1;

  &::after {
    content: "";
    flex: 1;
  }
`;

export const CloseButtonWrapper = styled.div`
  width: 18px;
  display: flex;
  align-items: center;
  flex: 1;
`;

export const SelectorTitle = styled.span`
  align-self: center;
  font-family: "Roboto-Bold", sans-serif;
  font-size: 14px;
  color: var(--fd-colors-content-strong);
`;

export const YearWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const YearLabel = styled.div`
  width: 100%;
  padding: 8px 16px;
  background-color: var(--fd-colors-background-base);
  color: var(--fd-colors-content-strong);
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
`;

export const PickerWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 8px 12px;
`;

export const PickerButtonWrapper = styled.div<{
  isSelectedMonth: boolean;
}>`
  width: 33.3%;

  button {
    width: 100%;
  }
`;
