import { get } from "lodash";
import { useEffect, useLayoutEffect } from "react";
import { Location } from "react-router-dom";
import { Dispatch } from "redux";
import { History } from "@tvg/ts-types/History";
import mediator from "@tvg/mediator";
import {
  BetCancelResult,
  CheckSelectionLabel
} from "@tvg/sh-lib-my-bets/utils/types";
import formatSequencial from "@tvg/formatter/numeric";
import { BetSelection, BetStatus } from "@tvg/ts-types/Bet";
import {
  BettingInterest,
  RaceInfoMyBets,
  RaceStatusEnum,
  RaceWagerType
} from "@tvg/ts-types/Race";

import { isEqual } from "date-fns";
import { Selections } from "@tvg/ts-types/Selections";
import { WroWager } from "@tvg/ts-types/WroWager";
import { TabKey } from "@tvg/ts-types/Generic";
import { NullaryFn, UnaryFn } from "@tvg/ts-types/Functional";
import { ActiveTabEnum, SettledTabEnum } from "./types";
import { closeMyBetsStandalone } from "../redux/actions";
import { onToggleShowDetails } from "./gtm";
import {
  isExoticBet,
  isMultiRaceBet,
  isPickBetWager,
  useIsXsellBlacklistedBet
} from "./raceDetails";

export const calculateProbableValue = (
  comboAmount: number,
  minWagerAmount: number,
  userAmount: number
): number => (comboAmount * userAmount) / minWagerAmount;

export const SELECTIONS_MODIFIERS = {
  KEY: "KY",
  KEY_BOX: "KYBX",
  BOX: "BX"
};

export const isSuccessBetCancel = (betCancelNotification: BetCancelResult) =>
  get(betCancelNotification, "status", "") === "success";

export const closeModal = (
  dispatch: Dispatch,
  location?: Location,
  history?: History
) => {
  if (location !== undefined && history !== undefined) {
    const currentPath = `${location.pathname}${location.search}`;
    history.push(currentPath);
  }
  dispatch(closeMyBetsStandalone());
  mediator.base.dispatch({ type: "MYBETS_MODAL_CLOSE" });
};

export const scrollToView = (element: Element | HTMLElement | null) => {
  if (
    element &&
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.userAgent &&
    typeof get(element, "scrollIntoView") === "function"
  ) {
    const ua = window.navigator.userAgent;
    if (
      (!!ua.match(/Safari/i) || !!ua.match(/AppleWebKit/i)) &&
      !ua.match(/Chrome/i)
    ) {
      setTimeout(() => {
        element.scrollIntoView();
      }, 0);
    } else {
      element.scrollIntoView();
    }
  }
};

export const scrollActivePillIntoView = () => {
  const elements = document.getElementsByClassName("active");
  if (elements) {
    Array.from(elements).forEach((el) => {
      if (el.className.includes("PillAtom")) {
        scrollToView(el);
      }
    });
  }
};

export const scrollActiveBetIntoView = (indexBuffer: number) => {
  if (indexBuffer !== 0) {
    const elmnt = document.getElementById(`activebet-${indexBuffer}`);
    scrollToView(elmnt);
  }
};

export const useIsomorphicLayoutEffect =
  typeof window !== "undefined" ? useLayoutEffect : useEffect;

export const changeTab = (
  selectedTab: TabKey,
  setSelectedTab: UnaryFn<TabKey, void>
) => {
  setSelectedTab(selectedTab);
  return true;
};

export const getWinType = (winningsAmount: number, betAmount: number) => {
  let winType;

  if (winningsAmount < betAmount * 5) {
    winType = "SmallWin";
  } else if (
    winningsAmount >= betAmount * 5 &&
    winningsAmount < betAmount * 10
  ) {
    winType = "Win";
  } else if (winningsAmount >= betAmount * 10) {
    winType = "BigWin";
  }

  return winType;
};

export const getSelectionLabel = ({
  raceNumber,
  selectionLength,
  selectionIndex,
  selectionsModifier,
  isMultiRace,
  isKey,
  isBox,
  isDesktop
}: CheckSelectionLabel) => {
  const isKeyRace = isKey || selectionsModifier === SELECTIONS_MODIFIERS.KEY;
  const isKeyBox = isBox || selectionsModifier === SELECTIONS_MODIFIERS.KEY_BOX;
  const isBoxRace = isBox || selectionsModifier === SELECTIONS_MODIFIERS.BOX;
  let pickBetsLabelLength = 10;
  let key = false;
  let label = "";
  // check multiple selections
  if (selectionLength > 1) {
    key =
      (selectionIndex === 0 && isKeyRace) || (selectionIndex === 0 && isKeyBox);
    if (isKeyBox && selectionIndex !== 0) {
      label = "Box";
    } else if (isKeyRace && selectionIndex !== 0) {
      label = "W/";
    } else {
      const legNumber = selectionIndex + 1;
      if (!isMultiRace) {
        label = formatSequencial(legNumber);
      } else {
        label = isDesktop
          ? `R${raceNumber + selectionIndex}`
          : `LEG ${legNumber} - R${raceNumber + selectionIndex}`;
      }
    }
  } else if (isBoxRace) {
    label = "Box";
  }
  if (selectionLength >= 10 && raceNumber + selectionLength > 10) {
    pickBetsLabelLength = 12;
  } else if (selectionLength >= 10 || raceNumber + selectionLength > 10) {
    pickBetsLabelLength = 11;
  }
  return {
    label,
    key,
    pickBetsLabelLength
  };
};

export const checkAllDetailedViewIsOpen = (showBetsDetailedView: boolean[]) =>
  showBetsDetailedView &&
  !!showBetsDetailedView.length &&
  typeof showBetsDetailedView.find((isOpen) => !isOpen) === "undefined";

export const toggleAllDetailViews = (
  showBetsDetailedView: boolean[],
  setShowBetsDetailedView: UnaryFn<Array<boolean>, void>,
  selections: BetSelection[][] | Selections[][] = []
) => {
  const closeAll = checkAllDetailedViewIsOpen(showBetsDetailedView);

  const showBetsDetailedViewState = new Array(selections.length).fill(
    !closeAll
  );
  setShowBetsDetailedView(showBetsDetailedViewState);
};

export const setShowContent = (
  value: boolean,
  id: string,
  showContentObj: Record<string, boolean>,
  setShowContentObj: UnaryFn<Record<string, boolean>, void>
) => {
  const auxObj = { ...showContentObj };
  auxObj[id] = value;
  setShowContentObj(auxObj);
};

export const handleDetailsButton = (
  selectedTab: ActiveTabEnum,
  selectedSettledTab: SettledTabEnum,
  isDetailsOpened: boolean,
  showBetsDetailedView: boolean[],
  setShowBetsDetailedView: UnaryFn<boolean[], void>,
  // eslint-disable-next-line @typescript-eslint/default-param-last
  selections: BetSelection[][] | Selections[][] = [],
  handleScrollPosition: NullaryFn<void>,
  totalActiveBets?: number,
  totalSettledBets?: number
) => {
  onToggleShowDetails({
    selectedTab,
    selectedSettledTab,
    show: !isDetailsOpened,
    activeBetsCount: totalActiveBets,
    settledBetsCount: totalSettledBets
  });
  toggleAllDetailViews(
    showBetsDetailedView,
    setShowBetsDetailedView,
    selections
  );
  handleScrollPosition();
};

export const getWagerToRender = (
  wagers: WroWager[],
  selectedWager?: WroWager
) => {
  if (selectedWager) {
    return [selectedWager];
  }

  return wagers;
};

// Rule to protect wagers being shown from previous day when BE doesnt have new info from TOTE
export const shouldRenderWager = (
  selectedTab: ActiveTabEnum,
  currentRaceDate: string,
  wagerRaceDate: string,
  trackNameFilters?: string[],
  trackName?: string
) => {
  if (trackNameFilters && trackNameFilters.length && trackName) {
    return trackNameFilters.includes(trackName);
  }

  return selectedTab !== "ACTIVE" || currentRaceDate === wagerRaceDate;
};

export const checkWagerIsCancelable = (
  wager: WroWager,
  statusCode: RaceStatusEnum
) => {
  const cancelableStatus: RaceStatusEnum[] = [
    RaceStatusEnum.OPEN,
    RaceStatusEnum.UP_NEXT
  ];
  return (
    get(wager, "cancelable", false) && cancelableStatus.includes(statusCode)
  );
};

export const getWagerBetStatus = (wagerBetStatus: BetStatus) => ({
  isCanceled: wagerBetStatus.name === "canceled",
  isActive: wagerBetStatus.code === "A"
});

export const checkWagerIsBetWinner = (wager: WroWager) =>
  get(wager, "winningsAmount", 0) > get(wager, "betTotal", 0);

export const getWagerProps = (
  wager: WroWager,
  statusCode: RaceStatusEnum,
  currentRaceDate: string,
  mainWagerDetails: string,
  isURPPpOnXSellEnabled: boolean,
  currentRace?: RaceInfoMyBets
) => {
  const wagerBetStatus = get(wager, "betStatus", {} as BetStatus);
  const currentWagerTypeCode = get(wager, "wagerType.code", "");
  const currentWagerTypeId = get(wager, "wagerType.id", 10);
  const raceWagerTypes = get(currentRace, "wagerTypes", []);
  const currentWagerTypeByRace = raceWagerTypes.find(
    (wagerType: RaceWagerType) =>
      get(wagerType, "type.id") === currentWagerTypeId
  );

  const isOptedIn = get(wager, "promoQualifying", false);
  const isCurrentRaceDate = isEqual(
    new Date(mainWagerDetails),
    new Date(currentRaceDate)
  );
  const { isCanceled, isActive } = getWagerBetStatus(wagerBetStatus);
  const isWagerCancelable = checkWagerIsCancelable(wager, statusCode);
  const isMultiRace = isMultiRaceBet(currentWagerTypeCode);
  const isPickBet = isPickBetWager(currentWagerTypeCode);
  const isExotic = isExoticBet(currentWagerTypeCode);
  const isXsellBlacklisted = useIsXsellBlacklistedBet(
    currentWagerTypeCode,
    isURPPpOnXSellEnabled
  );
  const isBetWinner = checkWagerIsBetWinner(wager);
  const isWheel = get(currentWagerTypeByRace, "isWheel", false);
  const isKey = get(currentWagerTypeByRace, "isKey", false);
  const isBox = get(currentWagerTypeByRace, "isBox", false);

  return {
    wagerBetStatus,
    isCurrentRaceDate,
    isCanceled,
    isActive,
    currentWagerTypeCode,
    currentWagerTypeId,
    isWagerCancelable,
    isMultiRace,
    isPickBet,
    isExotic,
    isBetWinner,
    isOptedIn,
    isWheel,
    isKey,
    isBox,
    isXsellBlacklisted
  };
};

export const isFavoriteRunner = (
  raceDate: string,
  currentBiNumber: number,
  race?: RaceInfoMyBets
): boolean => {
  const bettingInterestsRaceDate = get(race, `raceDate`, "");
  if (bettingInterestsRaceDate === raceDate) {
    const bettingInterests = get(
      race,
      `bettingInterests`,
      [] as Array<BettingInterest>
    );
    return bettingInterests.reduce(
      (isFavorite: boolean, betInterests: BettingInterest) => {
        const betInterestsFavorite =
          get(betInterests, "favorite", false) ||
          get(betInterests, "isFavorite", false);
        const biNumber = get(betInterests, "biNumber");

        return (
          isFavorite ||
          (bettingInterestsRaceDate === raceDate &&
            betInterestsFavorite &&
            biNumber === currentBiNumber)
        );
      },
      false
    );
  }

  return false;
};
