import React, { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { getAccountModalOpened } from "@urp/unified-modules/src/redux/selector/account";

import { PageHeader } from "../PageHeader";
import { AlertInLine } from "../AlertInLine";
import {
  Backdrop,
  ANIMATION_DURATION,
  ModalContainer,
  ModalContent,
  ModalFooter,
  TopStickyArea,
  ModalHeaderContainer,
  PagesSlider
} from "./styled-components";
import type { ModalProps } from "./types";
import { Scroll } from "../Scroll";

export const Modal = ({
  isOpen,
  headerProps,
  customHeader,
  alertProps = [],
  onClose,
  hasMaxHeight = false,
  maxHeight,
  hasPadding = true,
  footerContent,
  stickyAreaExtraContent,
  onAfterClose,
  qaLabel,
  hideOverlayColor = false,
  maxWidth,
  hasPages = false,
  hideHeader = false,
  page,
  overrideInset = undefined,
  onOpen,
  mode = "light",
  children,
  ...rest
}: ModalProps) => {
  const portalRef = useRef<HTMLDivElement | null>(null);
  const isAccountModalOpened = useSelector(getAccountModalOpened);
  const stickyAreaRef = useRef<HTMLDivElement>(null);
  const [isClosing, setIsClosing] = useState(false);
  const [visible, setVisible] = useState(isOpen);
  const [slideDirection, setSlideDirection] = useState<
    "left" | "right" | "none"
  >("none");
  const prevPage = useRef<number | undefined>(undefined);

  useEffect(() => {
    let timer = 0;

    if (isOpen) {
      const portalElement = document.createElement("div");
      portalElement.id = `portal-${qaLabel || uuid()}`;

      document.body.appendChild(portalElement);
      portalRef.current = portalElement;
      document.body.style.overflow = "hidden";
      if (onOpen) {
        onOpen();
      }
      setIsClosing(false);
      setVisible(true);
    } else {
      setIsClosing(true);
      document.body.style.overflow = "auto";
      timer = setTimeout(() => {
        setVisible(false);
        setIsClosing(false);
        if (onAfterClose) onAfterClose();
        if (portalRef.current) {
          document.getElementById(`portal-${qaLabel}`)?.remove();
          portalRef.current.remove();
          portalRef.current = null;
        }
      }, ANIMATION_DURATION - 20);
    }

    return () => clearTimeout(timer);
  }, [isOpen]);

  useEffect(() => {
    if (page !== undefined) {
      if (prevPage.current !== page) {
        if (prevPage.current !== undefined) {
          setSlideDirection(prevPage.current > page ? "left" : "right");
          setTimeout(() => {
            setSlideDirection("none");
          }, ANIMATION_DURATION + 10);
        }

        prevPage.current = page;
      }
    }
  }, [page]);

  useEffect(() => {
    const portal = document.getElementById(`portal-${qaLabel}`);
    if (portal) {
      if (isAccountModalOpened) {
        portal.style.visibility = "hidden";
      }

      if (!isAccountModalOpened) {
        portal.style.visibility = "visible";
      }
    }
  }, [isAccountModalOpened]);

  if (!visible) return null;

  const modalContent = (
    <>
      <Backdrop
        onClick={onClose}
        data-closing={isClosing}
        data-hide-overlay={hideOverlayColor}
        id={`backdrop-${qaLabel}`}
      />
      <ModalContainer
        id={qaLabel}
        data-qa-label={qaLabel}
        maxHeight={maxHeight}
        maxWidth={maxWidth}
        data-closing={isClosing}
        hasMaxHeight={hasMaxHeight}
        overrideInset={overrideInset}
      >
        {headerProps && !hideHeader ? (
          <PageHeader {...headerProps} onClose={onClose} />
        ) : null}
        {customHeader ? (
          <ModalHeaderContainer> {customHeader} </ModalHeaderContainer>
        ) : null}
        <TopStickyArea ref={stickyAreaRef}>
          {alertProps?.length
            ? alertProps.map((props) => (
                <AlertInLine
                  {...props}
                  onDismissPress={(e) => {
                    if (props.onDismissPress) {
                      props.onDismissPress(e);
                    }
                  }}
                />
              ))
            : null}
          {stickyAreaExtraContent}
        </TopStickyArea>

        <Scroll
          overflowY="auto"
          overflowX={hasPages ? "hidden" : "auto"}
          hasShadow
          height="100%"
          maxHeight={`calc(${maxHeight || "100vh"} - ${hideHeader ? "0" : "100px"})`}
          isDark={mode === "dark"}
          width="100%"
        >
          <ModalContent
            padding={hasPages && 0}
            hasMaxHeight={hasMaxHeight}
            hasPadding={hasPadding}
            {...rest}
          >
            {hasPages ? (
              <PagesSlider data-slide={slideDirection}>{children}</PagesSlider>
            ) : (
              children
            )}
          </ModalContent>
        </Scroll>

        {footerContent && <ModalFooter>{footerContent}</ModalFooter>}
      </ModalContainer>
    </>
  );

  if (process.env.JEST_WORKER_ID !== undefined) return modalContent;

  if (portalRef.current) {
    return createPortal(modalContent, portalRef.current);
  }

  return null;
};

export type { ModalProps };
