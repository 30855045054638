import { gql } from "@apollo/client";

export const GET_PAST_RACE = gql`
  query getPastRace(
    $wagerProfile: String!
    $trackCode: String
    $raceNumber: String
    $date: String
  ) {
    pastRaces(
      trackCode: $trackCode
      raceNumber: $raceNumber
      date: $date
      profile: $wagerProfile
    ) {
      id
      results {
        winningTime
        runners {
          entityRunnerId
          betAmount
          biNumber
          runnerNumber
          finishPosition
          placePayoff
          runnerName
          showPayoff
          winPayoff
          timeform {
            postRaceReport
            accBeatenDistance
            accBeatenDistanceStatus
            winningDistance
            winningDistanceStatus
          }
        }
        payoffs {
          selections {
            payoutAmount
            selection
          }
          wagerAmount
          wagerType {
            code
            name
          }
        }
      }
      bettingInterests {
        biNumber
        numberColor
        saddleColor
        favorite
        currentOdds {
          numerator
          denominator
        }
        morningLineOdds {
          numerator
          denominator
        }
        runners {
          handicapping {
            speedAndClass {
              avgClassRating
              highSpeed
              avgSpeed
              lastClassRating
              avgDistance
            }
            averagePace {
              finish
              numRaces
              middle
              early
            }
            jockeyTrainer {
              places
              jockeyName
              trainerName
              shows
              wins
              starts
            }
            snapshot {
              powerRating
              daysOff
              horseWins
              horseStarts
            }
            freePick {
              number
              info
            }
          }
          horseName
          runnerId
          trainer
          jockey
          scratched
        }
      }
    }
  }
`;

export default GET_PAST_RACE;
