import styled, { css } from "styled-components";
import { breakpoints } from "@tvg/design-system";
import horseImage from "../../_static/images/hr-video-placeholder.jpg";

import buildColor from "../../_static/ColorPalette";

export const InfoContainer = styled.div`
  display: flex;
  border-bottom: 1px solid ${buildColor("blue", "100")};
`;

export const HandicapInfoContainer = styled.div`
  width: 100%;
`;

export const RunnerCell = styled.td`
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
`;

export const RaceOfficialsWrapper = styled.div`
  border-top: 1px solid ${buildColor("blue", "100")};
`;

export const LoadingState = styled.div`
  width: 100%;
  height: 100%;
  ${({ hasBackground }) =>
    hasBackground &&
    css`
      background-color: ${buildColor("white", "100")};
    `}
`;

export const ContentWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${buildColor("white", "100")};
  ${(props) =>
    props.withBorders &&
    css`
      border-radius: 2px;
      border: 1px solid ${buildColor("grey", "300")};
    `};
`;

export const ModalWrapper = styled.div`
  background-color: ${buildColor("white", "100")};
`;

export const VideoContainer = styled.div`
  position: relative;
  background-color: ${(props) =>
    props.isVideoAvailable
      ? buildColor("white", "100")
      : buildColor("black", "100")};
  ${({ isTvg5, isMobile }) =>
    isTvg5 && !isMobile
      ? css`
          height: 600px;
        `
      : css`
          /* forces video to be 4:3 aspect ratio */
          height: 0;
          padding-top: 75%;
        `}

  & > div {
    ${({ isTvg5, isMobile }) =>
      isTvg5 && !isMobile
        ? css`
            height: 600px;
          `
        : css`
            position: absolute;
            inset: 0;
          `}
  }
`;

export const NoReplayContainer = styled.div`
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url("${horseImage}");
  background-position: 0 0;
  background-size: cover;
  background-repeat: no-repeat;
  flex-direction: column;
  text-align: center;
  opacity: 0.7;
`;
export const NoReplayContainerMessage = styled.div`
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  flex-direction: column;
  text-align: center;
  z-index: 10;
`;

export const IconContainer = styled.span`
  width: 100%;
`;
export const MessageContainer = styled.span`
  margin-top: 8px;
  font-size: 12px;
  color: ${buildColor("white", "100")};
`;

export const UnavailableVideoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--fd-colors-core-blue-d-4);
  padding: 0 56px;

  & > :first-child svg {
    width: 96px;
    height: 96px;
  }

  @media screen and ${breakpoints.tablet.min.sm} {
    flex-grow: 1;
  }
`;
