import styled, { css } from "styled-components";

export const TextInputContainer = styled.div<{
  isFocused: boolean;
  hasWarning: boolean;
}>`
  width: 100%;
  ${({ isFocused, hasWarning, theme }) => css`
    border: 1px solid
      ${isFocused
        ? hasWarning
          ? theme.colorTokens.content.warning
          : theme.colors.blue_accent["500"]
        : theme.colors.grey["500"]};
  `}
  display: flex;
  padding: ${({ theme }) => theme.space["space-4"]};
  border-radius: ${({ theme }) => theme.radii.s};
  box-shadow: ${({ theme }) => theme.elevations.low};
`;

export const SearchIcon = styled.span`
  margin-right: 10px;
`;

export const TextInput = styled.input`
  flex: 1;

  &::placeholder {
    font-size: ${({ theme }) => theme.fonts.s};
    font-family: inherit;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

export const ListItemsContainer = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  background-color: ${({ theme }) => theme.colorTokens.background.surface};
`;

export const SearchBarContainer = styled.div`
  display: flex;
`;

export const CloseButton = styled.button`
  background-color: transparent;
  padding: 0 8px;
  cursor: pointer;
`;
