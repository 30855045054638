import React from "react";

import { Icon } from "../../../Icon";
import { Button } from "../styled-components";

import type { BackButtonProps } from "./types";

export const BackButton = ({ onBack, ...rest }: BackButtonProps) => (
  <Button onClick={onBack} {...rest}>
    <Icon size="m" lineColor="--fd-colors-content-default" name="arrowLeft" />
  </Button>
);
