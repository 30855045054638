import tvgConf from "@tvg/conf";
import Cookies from "universal-cookie";

export const cookies = new Cookies();

export const getDomainForCookie = () => {
  let cookieDomain: string | undefined;
  if (typeof window !== "undefined") {
    const domain = window.location.hostname;
    const environment = tvgConf().environment as string;
    cookieDomain = ["local", environment].some((env) => domain.startsWith(env))
      ? domain.substring(domain.indexOf("."))
      : `.${domain}`;
  }

  return cookieDomain;
};

const getDomainWithoutSubdomain = () => {
  if (typeof window === "undefined") return undefined;

  const hostnameParts = window.location.hostname.split(".");
  const domainWithoutSubdomain = hostnameParts.slice(-2).join(".");
  return `.${domainWithoutSubdomain}`;
};

export const getCookieOptions = (removeSubdomain = false) => ({
  domain: !removeSubdomain ? getDomainForCookie() : getDomainWithoutSubdomain(),
  path: "/",
  doNotParse: false
});

export const addCookie = (
  cookieName: string,
  cookieValue: string,
  maxAge?: number,
  removeSubdomain = false
): void => {
  if (getCookieValue(cookieName)) {
    removeCookie(cookieName);
  }

  cookies.set(cookieName, cookieValue, {
    ...getCookieOptions(removeSubdomain),
    maxAge
  });
};

export const getCookieValue = <T = string>(
  cookieName: string,
  defaultValue?: T,
  forceParse = true
): T =>
  cookies.get(cookieName, {
    ...getCookieOptions(),
    doNotParse: !forceParse
  }) || defaultValue;

export const removeCookie = (cookieName: string): void => {
  cookies.remove(cookieName, getCookieOptions());
};
