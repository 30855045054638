import styled from "styled-components";

export const HeaderWrapper = styled.div<{ placeBetSuccess: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 16px;
  justify-content: center;
  position: relative;
`;

export const CloseButton = styled.div`
  width: 20px;
  height: 20px;
  position: absolute;
  right: 16px;
  top: 16px;
  cursor: pointer;
`;

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;
