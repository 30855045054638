import styled from "styled-components";
import ButtonDefault from "@tvg/atomic-ui/_atom/Buttons/default";
import buildColor from "@tvg/atomic-ui/_static/ColorPalette";
import { fontNormal } from "@tvg/atomic-ui/_static/Typography";

export const ActionsWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ContentRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

export const IconButton = styled(ButtonDefault)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40px;
  font-family: ${fontNormal};
  font-size: 12px;
  font-weight: 500;
  line-height: 17px;

  svg {
    padding: 10px;
    border-radius: 50%;
  }

  &:hover svg,
  &:focus svg {
    background-color: ${(props: { isCancelButton?: boolean }) =>
      props.isCancelButton
        ? buildColor("red", "100")
        : buildColor("grey", "100")};
  }

  &:active svg {
    background-color: ${(props: { isCancelButton?: boolean }) =>
      props.isCancelButton
        ? buildColor("red", "200")
        : buildColor("grey", "200")};
  }
`;

export const DeleteButtonWrapper = styled.div`
  &:active {
    svg {
      path {
        fill: transparent;
      }
    }
  }
`;
