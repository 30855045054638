import React, { Component, Fragment } from "react";
import { bindAll, isEqual, get, noop, first } from "lodash";

import { Tabs, Tab, TabList, TabPanel, Counter } from "@tvg/design-system/web";
import handicappingOptions, {
  handicappingGreyhoundOptions,
  handicappingHarnessOptions
} from "@tvg/utils/handicapping-conf/handicappingOptions";

import { checkTimeformData } from "@tvg/sh-lib-my-bets/utils/bettingInterests";
import MyBetsBody from "@tvg/my-bets-standalone/src/MyBetsBody";
import RunnerListHeader from "@tvg/ipp/src/components/RunnerListHeader";
import RunnerList from "@tvg/ipp/src/components/RunnerList";
import RaceMessageRedirect from "@tvg/ipp/src/components/RaceMessageRedirect";
import RaceRunnersPayoffsReDesign from "../../_organism/RaceRunners";
import RaceRunnersPayoffs from "../../_organism/RaceRunnersPayoffs";
import RacePayoffs from "../../_organism/RacePayoffs";
import RaceBets from "../../_organism/RaceBets";
import { ResultsMask } from "../../_static/Masks";
import RaceResults from "../../_organism/RaceResults";
import {
  RaceCardTableWrapper,
  RaceCardTable
} from "../RaceCard/styled-components";

import {
  ResultsContainer,
  RaceBetsContainer,
  Alerts,
  NoBetsContainer,
  TextHighlighted,
  ROWrapper,
  Message
} from "./styled-components";

import { handicapping } from "../../_static/Icons/icons";

export default class RaceOfficials extends Component {
  static defaultProps = {
    resultsType: "official",
    bettingInterests: [],
    scratchedRunners: [],
    results: null,
    fullResults: [],
    runnerName: "",
    raceTypeCode: "T",
    isResultsPending: true,
    bets: [],
    races: [],
    wagerGroup: null,
    myBetsResultedBetTicket: false,
    defaultTab: "racecard",
    onTabClick: () => false,
    legExpandedRunner: "",
    runnerExpandLine: noop,
    runnerExpandInfo: noop,
    inLinePastPerformance: false,
    handicapType: "basic",
    openHandicapCallback: noop,
    raceNumber: "1",
    firstHandicapBold: false,
    nextRaceUrl: "",
    tracksUrl: "",
    onNextRaceClick: noop,
    onChangeTrackClick: noop,
    hasNoHandicappingSelection: false,
    hasTimeformData: false,
    winningTime: "",
    isGreyhound: false,
    device: "mobile",
    trackDataSource: "",
    enableRaceResultsReDesign: false,
    seeAllHorsesGTM: noop,
    scrollRunnerPP: false,
    scrollOffset: 0,
    handicappingRunnerFlags: {
      title: "",
      subtitle: "",
      subtitleRunnerFlags: "",
      flags: []
    },
    raceId: "0",
    racePayoffsShift: false
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedtab:
        this.props.defaultTab === "none" ? "racecard" : this.props.defaultTab
    };

    bindAll(this, "handleTabClick", "renderResultTab", "getHandicappingFormat");
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.state.selectedtab !== nextProps.defaultTab) {
      this.setState({
        selectedtab:
          nextProps.defaultTab === "none" ? "racecard" : nextProps.defaultTab
      });
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return (
      nextState.selectedtab !== this.state.selectedtab ||
      nextProps.defaultTab !== this.props.defaultTab ||
      nextProps.bettingInterests.length !==
        this.props.bettingInterests.length ||
      get(nextProps, "results.runners", []).length !==
        get(this.props, "results.runners", []).length ||
      get(nextProps.results, "payoffs", []).length !==
        get(this.props.results, "payoffs", []).length ||
      !isEqual(nextProps, this.props)
    );
  }

  handleTabClick(newTab) {
    if (this.state.selectedtab !== newTab && this.props.onTabClick(newTab)) {
      this.setState({ selectedtab: newTab });
    }
  }

  getHandicappingFormat(handicapKey) {
    let option = [];
    if (this.props.raceTypeCode === "G") {
      option = handicappingGreyhoundOptions;
    } else if (this.props.raceTypeCode === "H") {
      option = handicappingHarnessOptions;
    } else {
      option = handicappingOptions;
    }

    const findRes =
      option.find((handicapItem) => handicapItem.key === handicapKey) ||
      option[0];

    return handicapKey === "basic"
      ? {
          title: findRes.title
        }
      : findRes;
  }

  renderMyBets() {
    return this.props.bets.length > 0 ? (
      <RaceBetsContainer key="bets-tab">
        <RaceBets bets={this.props.bets} raceNumber={this.props.raceNumber} />
      </RaceBetsContainer>
    ) : (
      <NoBetsContainer>
        <Alerts isVertical type="info" message="You've no bets on this race" />
      </NoBetsContainer>
    );
  }

  renderNewMyBets() {
    const { wagerGroup, races } = this.props;
    return wagerGroup && wagerGroup.wagers.length > 0 ? (
      <RaceBetsContainer key="bets-tab" myBetsBody>
        <MyBetsBody
          bet={wagerGroup}
          races={races || []}
          currentRaceDate={first(wagerGroup.value.split("|"))}
          hasPastPerformance={false}
          showLiveVideo={false}
          isInsideRaceOfficials
        />
      </RaceBetsContainer>
    ) : (
      <NoBetsContainer>
        <Alerts isVertical type="info" message="You've no bets on this race" />
      </NoBetsContainer>
    );
  }

  renderResultTab() {
    return (
      <ResultsContainer>
        {!this.props.isResultsPending && (
          <Fragment>
            {this.props.enableRaceResultsReDesign ? (
              <RaceRunnersPayoffsReDesign
                shouldExpandMoreRunners={checkTimeformData(
                  get(this.props, "results")
                )}
                raceTypeCode={this.props.raceTypeCode}
                runners={get(this.props.results, "runners", [])}
                bettingInterests={this.props.bettingInterests}
                winningTime={this.props.winningTime}
                runnerNameHighlighted={this.props.runnerName}
                raceId={this.props.raceId}
                racePayoffsShift={this.props.racePayoffsShift}
              />
            ) : (
              <RaceRunnersPayoffs
                raceTypeCode={this.props.raceTypeCode}
                runners={get(this.props.results, "runners", [])}
              />
            )}
            <RacePayoffs
              raceNumber={this.props.raceNumber}
              raceTypeCode={this.props.raceTypeCode}
              enableRaceResultsReDesign={this.props.enableRaceResultsReDesign}
              payoffs={get(this.props.results, "payoffs", [])}
            />
          </Fragment>
        )}
        {this.props.isResultsPending && (
          <Fragment>
            <RaceMessageRedirect
              title="Result pending"
              message="Full result will be posted once they are official"
              nextRaceUrl={this.props.nextRaceUrl}
              tracksUrl={this.props.tracksUrl}
              onNextRaceClick={this.props.onNextRaceClick}
              onChangeTrackClick={this.props.onChangeTrackClick}
            />
            <ResultsMask />
          </Fragment>
        )}
      </ResultsContainer>
    );
  }

  renderFullResultTab = () => {
    const {
      hasTimeformData,
      raceTypeCode,
      results,
      runnerName,
      fullResults,
      scratchedRunners,
      winningTime,
      enableRaceResultsReDesign,
      seeAllHorsesGTM
    } = this.props;

    return (
      <Fragment>
        {!hasTimeformData && (
          <Message
            enableRaceResultsReDesign={enableRaceResultsReDesign}
            type="info"
            message="Full result unavailable at this time"
          />
        )}
        {enableRaceResultsReDesign ? (
          <RaceRunnersPayoffsReDesign
            raceTypeCode={raceTypeCode}
            runners={get(results, "runners", [])}
            bettingInterests={this.props.bettingInterests}
            winningTime={winningTime}
            runnerNameHighlighted={runnerName}
            shouldExpandMoreRunners={this.props.hasTimeformData}
            raceId={this.props.raceId}
          />
        ) : (
          <React.Fragment>
            <RaceResults
              resultsType={hasTimeformData ? "full" : "basic"}
              runnerName={runnerName}
              runners={fullResults}
              scratchedRunners={scratchedRunners}
              raceTypeCode={raceTypeCode}
              winningTime={winningTime}
              seeAllHorsesGTM={seeAllHorsesGTM}
            />
            <RaceRunnersPayoffs
              raceTypeCode={raceTypeCode}
              runners={get(results, "runners", [])}
              displayRunner={false}
            />
          </React.Fragment>
        )}
        <RacePayoffs
          raceNumber={this.props.raceNumber}
          raceTypeCode={raceTypeCode}
          payoffs={get(results, "payoffs", [])}
          enableRaceResultsReDesign={enableRaceResultsReDesign}
        />
      </Fragment>
    );
  };

  renderRaceCardtab() {
    const handicappingFormat = this.getHandicappingFormat(
      this.props.handicapType
    );

    const handicapOptions = [
      {
        title: handicappingFormat.title,
        callback: this.props.openHandicapCallback,
        icon: handicapping,
        visible: true,
        qaLabel: "runnerListHeader-handicapInfoBtn"
      }
    ];

    return (
      <Fragment>
        {this.props.isResultsPending && (
          <RaceMessageRedirect
            isWarning
            hasBorderBottom
            message="Betting has now closed on this race"
            nextRaceUrl={this.props.nextRaceUrl}
            tracksUrl={this.props.tracksUrl}
            onNextRaceClick={this.props.onNextRaceClick}
            onChangeTrackClick={this.props.onChangeTrackClick}
          />
        )}
        <RaceCardTableWrapper
          removeMargin={this.props.enableRaceResultsReDesign}
          device={this.props.device}
        >
          <RaceCardTable
            device={this.props.device}
            noBorderOnFirstChild={this.props.hasNoHandicappingSelection}
          >
            {!this.props.hasNoHandicappingSelection && (
              <RunnerListHeader
                isWagerable={false}
                handicapOptions={handicapOptions}
                handicapInfo={get(handicappingFormat, "handicapData")}
                raceTypeCode={this.props.raceTypeCode}
                hasHandicappingSelection
              />
            )}
            <RunnerList
              runners={this.props.bettingInterests}
              raceTypeCode={this.props.raceTypeCode}
              isWagerable={false}
              numColumns={1}
              firstHandicapBold={this.props.firstHandicapBold}
              trackDataSource={this.props.trackDataSource}
              expandedRunner={this.props.legExpandedRunner}
              expandLine={this.props.runnerExpandLine}
              expandInfo={this.props.runnerExpandInfo}
              inLinePastPerformance={this.props.inLinePastPerformance}
              scrollRunnerPP={this.props.scrollRunnerPP}
              scrollOffset={this.props.scrollOffset}
              handicappingRunnerFlags={this.props.handicappingRunnerFlags}
            />
          </RaceCardTable>
        </RaceCardTableWrapper>
      </Fragment>
    );
  }

  getTitle = () => {
    return this.props.hasTimeformData ? "Full Result" : "Basic Result";
  };

  getTotalBets = () => {
    const { bets, myBetsResultedBetTicket, wagerGroup } = this.props;

    return myBetsResultedBetTicket
      ? wagerGroup?.wagers.length || 0
      : bets.length;
  };

  render() {
    const isDesktop = this.props.device !== "mobile";
    const sharedTabProps = {
      isTitleUppercase: !this.props.enableRaceResultsReDesign,
      ...(isDesktop && {
        hoverColor: "var(--fd-colors-background-base)",
        textTitleColor: "var(--fd-colors-content-default)",
        textTitleSelectedColor: "var(--fd-colors-content-default)",
        hasPressedColor: false
      })
    };
    return (
      <Fragment>
        <ROWrapper data-qa-label="raceOfficials">
          <Tabs
            selectedValue={this.state.selectedtab}
            onChange={this.handleTabClick}
            isStretched
            qaLabel="tabs"
          >
            <TabList
              qaLabel="tabList"
              isStretched
              tabSelectionIndicatorColor="var(--fd-colors-border-default)"
            >
              {this.props.resultsType === "full" ? (
                <Tab
                  value="fullresult"
                  title={
                    this.props.enableRaceResultsReDesign
                      ? "Result"
                      : this.getTitle()
                  }
                  qaLabel={
                    this.props.hasTimeformData
                      ? "raceofficials-fullresult"
                      : "raceofficials-basicresult"
                  }
                  {...sharedTabProps}
                />
              ) : (
                <Tab
                  value="result"
                  title="Result"
                  description={
                    this.props.isResultsPending ? (
                      <TextHighlighted>PENDING</TextHighlighted>
                    ) : (
                      ""
                    )
                  }
                  qaLabel="raceofficials-result"
                  {...sharedTabProps}
                />
              )}

              {this.getTotalBets() === 0 ? (
                <React.Fragment> </React.Fragment>
              ) : (
                <Tab
                  value="bets"
                  title="Bets"
                  isDisabled={this.getTotalBets() === 0}
                  counter={<Counter value={this.getTotalBets()} />}
                  qaLabel="raceofficials-bets"
                  {...sharedTabProps}
                />
              )}
              <Tab
                value="racecard"
                title="Racecard"
                qaLabel="raceofficials-racecard"
                isTitleUppercase={!this.props.enableRaceResultsReDesign}
                {...sharedTabProps}
              />
            </TabList>
            <TabPanel id="result" value="result">
              {this.renderResultTab()}
            </TabPanel>
            <TabPanel id="fullresult" value="fullresult">
              {this.renderFullResultTab()}
            </TabPanel>
            <TabPanel id="bets" value="bets">
              {this.props.myBetsResultedBetTicket
                ? this.renderNewMyBets()
                : this.renderMyBets()}
            </TabPanel>
            <TabPanel id="racecard" value="racecard">
              {this.renderRaceCardtab()}
            </TabPanel>
          </Tabs>
        </ROWrapper>
      </Fragment>
    );
  }
}
