import { UMEvents } from "../../types";
import type { Events } from "../types";

export default {
  // Account
  [UMEvents.Session]: [
    "x-sell/bridge/to-react/onTriggerLogin",
    "x-sell/bridge/to-native/loginFlowComplete"
  ],
  [UMEvents.Join]: ["x-sell/bridge/to-react/onTriggerJoin"],
  [UMEvents.KeepAlive]: ["x-sell/bridge/to-react/onUserTouch"],
  [UMEvents.AcceptTerms]: ["x-sell/bridge/to-react/onTriggerAcceptTerms"],
  [UMEvents.Verification]: ["x-sell/bridge/to-react/onTriggerVerification"],
  [UMEvents.OpenSettings]: ["x-sell/bridge/to-react/onTriggerAccountSummary"],
  [UMEvents.FirstLoad]: ["x-sell/bridge/to-react/firstLoadComplete"],
  // Wallet
  [UMEvents.Deposit]: ["x-sell/bridge/to-react/onTriggerDeposit"],
  // Only Subscription
  [UMEvents.SessionUpdate]: [
    undefined,
    "x-sell/bridge/to-native/authDetailsUpdated"
  ],
  [UMEvents.Logout]: [undefined, "x-sell/bridge/to-native/logoutFlowComplete"],
  [UMEvents.PresentModal]: [
    undefined,
    "x-sell/bridge/to-native/accountAndWalletClosed"
  ]
} as Events;
