export enum WagerTypeCodesEnum {
  WIN = "WN",
  WIN_PLACE = "WP",
  WIN_SHOW = "WS",
  WIN_PLACE_SHOW = "WPS",
  TRIFECTA = "TR",
  TRIFECTA_BOX = "TRB",
  TRIFECTA_WHEEL = "TRW",
  TRIFECTA_KEY = "TRK",
  TRIFECTA_KEY_BOX = "TKB",
  DAILY_DOUBLE = "DB",
  EXACTA = "EX",
  EXACTA_BOX = "EXB",
  EXACTA_WHEEL = "EXW",
  EXACTA_KEY = "EXK",
  EXACTA_KEY_BOX = "EKB",
  GRAND_SLAM = "GS",
  EXACT_12 = "E12",
  SURVIVOR = "SV",
  V_4 = "V4",
  V_5 = "V5",
  V_64 = "V64",
  V_65 = "V65",
  V_75 = "V75",
  V_86 = "V86",
  GS_75 = "GS75",
  PICK_3 = "P3",
  PICK_4 = "P4",
  PICK_5 = "P5",
  PICK_6 = "P6",
  PICK_7 = "P7",
  PICK_8 = "P8",
  PICK_9 = "P9",
  PICK_10 = "P10",
  PICK_11 = "P11",
  PICK_12 = "P12",
  PICK_ALL_3 = "A3",
  PICK_ALL_4 = "A4",
  PICK_ALL_5 = "A5",
  PICK_ALL_6 = "A6",
  PICK_ALL_7 = "A7",
  PICK_ALL_8 = "A8",
  PICK_ALL_9 = "A9",
  PICK_ALL_10 = "A10",
  PICK_ALL_11 = "A11",
  PICK_ALL_12 = "A12",
  PLACE = "PL",
  PLACE_PICK_ALL_3 = "L3",
  PLACE_PICK_ALL_4 = "L4",
  PLACE_PICK_ALL_5 = "L5",
  PLACE_PICK_ALL_6 = "L6",
  PLACE_PICK_ALL_7 = "L7",
  PLACE_PICK_ALL_8 = "L8",
  PLACE_PICK_ALL_9 = "L9",
  PLACE_PICK_ALL_10 = "L10",
  PLACE_SHOW = "PS",
  QUINELLA = "QN",
  QUINELLA_BOX = "QNB",
  QUINELLA_WHEEL = "QNW",
  SHOW = "SH",
  SUPERFECTA = "SU",
  SUPERFECTA_BOX = "SUB",
  SUPERFECTA_WHEEL = "SUW",
  SUPERFECTA_KEY = "SUK",
  SUPERFECTA_KEY_BOX = "SKB",
  SUPERHIGHFIVE = "SH5",
  SUPERHIGHFIVE_BOX = "H5B",
  SUPERHIGHFIVE_WHEEL = "H5W",
  SUPERHIGHFIVE_KEY = "S5K",
  SUPERHIGHFIVE_KEY_BOX = "5KB",
  OMNI = "OM",
  OMNI_BOX = "OMB",
  OMNI_WHEEL = "OMW",
  TRIO = "TI",
  TRIO_BOX = "TIB",
  TRIO_WHEEL = "TIW",
  UNKNOWN = ""
}

export enum WagerTypeWpsCodesEnum {
  WIN = "WN",
  WIN_PLACE = "WP",
  WIN_SHOW = "WS",
  WIN_PLACE_SHOW = "WPS",
  PLACE = "PL",
  PLACE_SHOW = "PS",
  SHOW = "SH"
}

export enum WagerTypeCodesInvertedEnum {
  WN = "WIN",
  WP = "WIN_PLACE",
  WS = "WIN_SHOW",
  WPS = "WIN_PLACE_SHOW",
  TR = "TRIFECTA",
  TRB = "TRIFECTA_BOX",
  TRW = "TRIFECTA_WHEEL",
  TRK = "TRIFECTA_KEY",
  TKB = "TRIFECTA_KEY_BOX",
  DB = "DAILY_DOUBLE",
  EX = "EXACTA",
  EXB = "EXACTA_BOX",
  EXW = "EXACTA_WHEEL",
  EXK = "EXACTA_KEY",
  EKB = "EXACTA_KEY_BOX",
  GS = "GRAND_SLAM",
  E12 = "EXACT_12",
  SV = "SURVIVOR",
  V4 = "V_4",
  V5 = "V_5",
  V64 = "V_64",
  V65 = "V_65",
  V75 = "V_75",
  V86 = "V_86",
  GS75 = "GS_75",
  P3 = "PICK_3",
  P4 = "PICK_4",
  P5 = "PICK_5",
  P6 = "PICK_6",
  P7 = "PICK_7",
  P8 = "PICK_8",
  P9 = "PICK_9",
  P10 = "PICK_10",
  P11 = "PICK_11",
  P12 = "PICK_12",
  A3 = "PICK_ALL_3",
  A4 = "PICK_ALL_4",
  A5 = "PICK_ALL_5",
  A6 = "PICK_ALL_6",
  A7 = "PICK_ALL_7",
  A8 = "PICK_ALL_8",
  A9 = "PICK_ALL_9",
  A10 = "PICK_ALL_10",
  A11 = "PICK_ALL_11",
  A12 = "PICK_ALL_12",
  PL = "PLACE",
  L3 = "PLACE_PICK_ALL_3",
  L4 = "PLACE_PICK_ALL_4",
  L5 = "PLACE_PICK_ALL_5",
  L6 = "PLACE_PICK_ALL_6",
  L7 = "PLACE_PICK_ALL_7",
  L8 = "PLACE_PICK_ALL_8",
  L9 = "PLACE_PICK_ALL_9",
  L10 = "PLACE_PICK_ALL_10",
  PS = "PLACE_SHOW",
  QN = "QUINELLA",
  QNB = "QUINELLA_BOX",
  QNW = "QUINELLA_WHEEL",
  SH = "SHOW",
  SU = "SUPERFECTA",
  SUB = "SUPERFECTA_BOX",
  SUW = "SUPERFECTA_WHEEL",
  SUK = "SUPERFECTA_KEY",
  SKB = "SUPERFECTA_KEY_BOX",
  SH5 = "SUPERHIGHFIVE",
  H5B = "SUPERHIGHFIVE_BOX",
  H5W = "SUPERHIGHFIVE_WHEEL",
  S5K = "SUPERHIGHFIVE_KEY",
  OM = "OMNI",
  OMB = "OMNI_BOX",
  OMW = "OMNI_WHEEL",
  TI = "TRIO",
  TIB = "TRIO_BOX",
  TIW = "TRIO_WHEEL"
}

export interface WagerType {
  id: number;
  code: WagerTypeCodesEnum;
  name: string;
  description?: string;
  descriptionInternational?: string;
  abbreviation?: string;
  unitedWagerTypeCode?: string;
}

export enum WagerTypesPos {
  Win,
  Place,
  Show
}

export enum WagerTypes {
  WIN = 10,
  PLACE = 20,
  SHOW = 30,
  WIN_PLACE = 40,
  WIN_SHOW = 50,
  PLACE_SHOW = 60,
  WIN_PLACE_SHOW = 70,
  QUINELLA = 80,
  EXACTA = 110,
  DAILY_DOUBLE = 310
}

export const WagerTypesGroup = {
  WIN_PLACE_SHOW: [
    WagerTypes.WIN,
    WagerTypes.PLACE_SHOW,
    WagerTypes.WIN_PLACE,
    WagerTypes.WIN_SHOW,
    WagerTypes.WIN_PLACE_SHOW,
    WagerTypes.SHOW,
    WagerTypes.PLACE
  ],
  WIN: [
    WagerTypes.WIN,
    WagerTypes.WIN_PLACE,
    WagerTypes.WIN_SHOW,
    WagerTypes.WIN_PLACE_SHOW
  ]
};

export interface WagerGroupsItem {
  errorMessage: string;
  group: string;
  title: string;
  value: string;
}

export interface WagerTypeDetails {
  group: WagerType;
  isBox: boolean;
  isKey: boolean;
  isWheel: boolean;
  legCount: number;
  type: WagerType;
}
