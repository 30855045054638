import { useEffect, useState } from "react";
import type { NullaryFn } from "@tvg/ts-types/Functional";
import type { Device } from "@tvg/ts-types/Device";
import { closeModalWrapper, toggleBodyClass } from "@tvg/utils/modalV2";
import usePrevious from "./usePrevious";

// Custom hook for core logic of ModalV2 in MEP and FDR
export const useModalV2Core = (
  isOpenProp: boolean,
  useFakeInput: boolean,
  isLastModal: boolean,
  device: Device,
  onOpen: NullaryFn<unknown>,
  forceModalTop: boolean = false,
  modalWrapperElementId: string = "modals"
) => {
  const previousPropsIsOpen = usePrevious(isOpenProp);
  const [isOpenState, setIsOpen] = useState(isOpenProp);
  const [bodyPositionY, setBodyPositionY] = useState(0);
  const [element, setElement] = useState<HTMLDivElement | null>(null);
  const [root, setRoot] = useState<HTMLElement | null>(null);
  const [readyToRender, setReadyToRender] = useState(false);

  const openModal = () => {
    setIsOpen(true);
    onOpen();

    if (isLastModal) {
      toggleBodyClass(true, bodyPositionY, setBodyPositionY, device);
    }
  };

  useEffect(() => {
    if (isOpenProp) {
      toggleBodyClass(true, bodyPositionY, setBodyPositionY, device);
    }

    if (
      previousPropsIsOpen !== undefined &&
      isOpenProp !== previousPropsIsOpen
    ) {
      if (!isOpenProp) {
        closeModalWrapper(() => {}, {
          useFakeInput,
          device,
          isLastModal,
          setIsOpen,
          bodyPositionY,
          setBodyPositionY
        });
      }

      if (isOpenProp) {
        openModal();
      }
    }
  }, [isOpenProp, previousPropsIsOpen]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const div = document.createElement("div");

      if (forceModalTop) {
        div.classList.add("forceModalTop");
      }

      setElement(div);
      const modalWrapper =
        document.getElementById(modalWrapperElementId) || document.body;
      setRoot(modalWrapper);
    }
  }, []);

  useEffect(() => {
    if (element && root) {
      root.appendChild(element);
      setReadyToRender(true);
    }
    return () => {
      if (root && element) {
        root.removeChild(element);
      }
    };
  }, [root, element]);

  return {
    isOpen: isOpenState,
    setIsOpen,
    bodyPositionY,
    setBodyPositionY,
    readyToRender,
    element
  };
};

export default useModalV2Core;
