import styled from "styled-components";
import { Button } from "@tvg/design-system/web";

export const RaceSection = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 12px 6px 12px 12px;
  background-color: ${({ theme }) => theme.colorTokens.content.onDark};

  & > * {
    flex-shrink: 0;
  }
`;

export const RaceInfo = styled.div`
  flex: 1 1 auto;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;

  & li {
    font-size: 14px;
  }
`;

export const TrackRace = styled.h3`
  display: flex;
  text-align: left;
  font-family: ${({ theme }) => theme.fonts.bold};
  font-size: 16px;
  line-height: 22px;
  color: ${({ theme }) => theme.colorTokens.content.default};
`;

export const TrackDate = styled.time`
  font-family: ${({ theme }) => theme.fonts.regular};
  display: flex;
  text-align: left;
  font-size: 14px;
  color: ${({ theme }) => theme.colorTokens.background.neutral};
  line-height: 18px;
`;

export const RaceDataList = styled.ul`
  list-style: none;
  text-align: left;
`;

export const RaceArrowLink = styled(Button)<{ isDisabled?: boolean }>`
  background: ${({ theme }) =>
    theme.colorTokens.component.button.secondaryDark.background.base};

  &:active {
    background-color: ${({ theme }) =>
      theme.colorTokens.component.button.secondaryDark.background.hover};
  }

  ${({ isDisabled }) =>
    isDisabled &&
    `
      svg {
        color: var(--fd-colors-component-button-secondary-content-disabled) !important;
        stroke: var(--fd-colors-component-button-secondary-content-disabled) !important;
       
        path {
          stroke: var(--fd-colors-component-button-secondary-content-disabled) !important;
        }
      }

  `}
`;

export const RaceArrowContent = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;

export const RaceArrowNavigation = styled.div`
  display: flex;
  flex: 0 0 72px;
  flex-direction: row;
  align-items: stretch;
  margin-left: 8px;
`;

export const NavigationTip = styled.span<{ hasCallback?: boolean }>`
  font-family: ${({ theme }) => theme.fonts.medium};
  font-size: 12px;
  color: ${({ hasCallback, theme }) =>
    hasCallback
      ? theme.colorTokens.content.default
      : theme.colorTokens.background.neutral};
`;
