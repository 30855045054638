import tvgConf from "@tvg/conf";
import { SourceKeysEnum, SourceValue } from "../types";

export const isFdr = () => tvgConf().brand === "fdr";

export const delay = (helper: { cancel: () => void }, ms: number) =>
  new Promise((resolve, reject) => {
    const timer = setTimeout(() => resolve("run"), ms);

    helper.cancel = () => {
      clearTimeout(timer);
      reject("cancelled");
    };
  });

export const isHongKongRaces = (code: string) =>
  code.includes("HKS") || code.includes("HKH");

export const buildQaLabel = (values: string[], separator: string = "-") =>
  values.join(separator);

export const buildSelectionSource = (
  origin: SourceKeysEnum | undefined,
  defaultValue: SourceValue = "Race Card"
): SourceValue => {
  if (origin === undefined) {
    return defaultValue;
  }

  const originMap = new Map<SourceKeysEnum, SourceValue>([
    [SourceKeysEnum.FDTV_PICKS, "fdtv_race_card"],
    [SourceKeysEnum.TRACKMASTER, "trackmaster_race_card"],
    [SourceKeysEnum.NUMBERFIRE, "numberfire_race_card"],
    [SourceKeysEnum.SELECT_ALL_RUNNERS, "Race Card"],
    [SourceKeysEnum.RACE_CARD, "Race Card"],
    [SourceKeysEnum.REPEAT_BET_RACE_CARD, "repeat_bet_race_card"],
    [SourceKeysEnum.REPEAT_BET_MY_BETS, "repeat_bet_my_bets"]
  ]);

  // When we guarantee a true extensive 1 to 1 map with no undefined allowed
  // this verification won't theoretically be necessary
  return originMap.get(origin) ?? defaultValue;
};

export const getFdtvType = (pathname: string) => {
  if (pathname) {
    const isFdtv = pathname.includes("/live");
    if (isFdtv) {
      return pathname.includes("TVG2") ? "fdr_tv" : "fdtv_plus";
    }
  }
  return "no_fdtv";
};
