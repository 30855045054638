import styled, { css } from "styled-components";
import { space } from "styled-system";

export const Container = styled.div<{ isSticky: boolean; top: number }>`
  display: flex;
  height: 48px;
  box-sizing: border-box;
  background-color: var(--fd-colors-background-surface);
  border-bottom: 1px var(--fd-border-styles-border-style-solid-style)
    var(--fd-colors-border-default);
  ${({ isSticky, top }) =>
    isSticky &&
    css`
      position: sticky;
      top: ${top}px;
    `};
  ${space}
`;

export const Tab = styled.button<{ isSelected: boolean }>`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  height: 47px;
  border: none;
  font-family: var(--fd-fonts-font-family-010), system-ui;
  font-size: var(--fd-font-sizes-font-size-040);
  font-weight: var(--fd-font-weights-font-weight-030);
  line-height: var(--fd-font-sizes-font-size-060);
  padding: 0 var(--fd-space-space-3);
  background-color: var(--fd-colors-background-surface);
  color: var(--fd-colors-content-default);

  @media (hover: hover) {
    &:hover:not(:active) {
      background-color: var(--fd-colors-background-hover);
      cursor: pointer;
    }
  }

  &:active {
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );
    color: var(--fd-colors-content-on-dark);
  }

  &::before {
    content: "";
    position: absolute;
    width: 100%;
    z-index: 20;
    background-color: transparent;
    pointer-events: none;
    height: 0;
    bottom: 0;
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      color: var(--fd-colors-content-link);

      &::before {
        background-color: var(--fd-colors-border-active);
        height: 2px;
      }
    `};
`;

export const EmblaSlider = styled.div<{ isStretched: boolean }>`
  max-width: 100%;

  ${({ isStretched }) =>
    isStretched &&
    css`
      width: 100%;
    `};
`;

export const EmblaViewport = styled.div`
  overflow: hidden;
`;

export const EmblaContainer = styled.div`
  display: flex;
`;
