import { get, upperFirst, isNumber } from "lodash";
import type { WagerProfile } from "@tvg/ts-types/User";
import { mapRunnersToFullResults } from "@tvg/sh-lib-my-bets/utils/bettingInterests";
import { getPortByBrand } from "@tvg/utils/generalUtils";

import { ApolloClient, WatchQueryFetchPolicy } from "@apollo/client";
import { PastRace } from "@tvg/ts-types/Race";
import { type Props } from "../types";

type GraphFcpProps = {
  runnerName?: string;
  runnerDob?: number;
  entityRunnerId?: string;
  trackCode?: string;
  raceNumber?: string | number;
  raceDate?: string;
  wagerProfile: WagerProfile;
  fcpClient: ApolloClient<unknown>;
  racesCounter?: number;
};

type GraphBehgProps = {
  trackCode?: string;
  raceNumber?: string | number;
  raceDate?: string;
  behgClient: ApolloClient<unknown>;
  accountNumber?: number | string;
  behgToggle: boolean;
  myBetsResultedBetTicket: boolean;
  isAccountCompliant?: boolean;
};

const QUERY_VARIABLES_IPP = {
  runnerName: "",
  sortBy: { byPostTime: "DESC" }
};

const QUERY_VARIABLES_RUNNER_STATS = {
  runnerName: "",
  runnerDob: 0
};

const QUERY_VARIABLES_PP = {
  trackCode: "",
  raceNumber: "",
  date: ""
};

export const getFinishPosition = (
  finishPosition: number | undefined | null,
  scratched: false
) => {
  if (scratched) {
    return 0;
  }

  return finishPosition;
};

const handleRunnerStats = (result: {}) => {
  const runnerStats = get(result, "data.runnerStats", {});
  const startsValue = get(runnerStats, "numberRaces", 0);
  const winsValue = get(runnerStats, "numberRacesWon", 0);
  const top3Value = get(runnerStats, "numberRacesTop3", 0);

  const wins =
    startsValue > 0 ? Math.floor((winsValue * 100) / startsValue) : undefined;

  const top3 =
    startsValue > 0 ? Math.floor((top3Value * 100) / startsValue) : undefined;

  return {
    runnerStats: {
      starts: startsValue,
      wins,
      top3
    },
    refetchStats: get(result, "data.refetch"),
    isLoadingStats: get(result, "data.loading", false)
  };
};

const handleResultsIPP = (result: {}) => {
  const raceIds: unknown[] = [];
  return {
    races: get(result, "data.pastRaces", []).map((race: PastRace) => {
      const runner = get(race, "results.runners[0]");
      const tempRace = {
        ...race,
        id: race.raceId,
        runnerNumber: get(runner, "runnerNumber", ""),
        hasReplays: !!get(race, "video.replayFileName", ""),
        replayFileName: get(race, "video.replayFileName", ""),
        raceTypeCode: get(race, "type.code"),
        distance: `${get(race, "distance.value", "")}${get(
          race,
          "distance.code",
          ""
        )}`.toLowerCase(),
        finishPosition: getFinishPosition(
          get(runner, "finishPosition"),
          get(runner, "scratched", false)
        ),
        favorite: get(runner, "favorite"),
        scratched: get(runner, "scratched", false),
        odds: get(runner, "currentOdds"),
        postRaceReport: upperFirst(get(runner, "timeform.postRaceReport", "")),
        accBeatenDistance: upperFirst(
          get(runner, "timeform.accBeatenDistance", "")
        ),
        accBeatenDistanceStatus: upperFirst(
          get(runner, "timeform.accBeatenDistanceStatus", "")
        ),
        accBeatenDistanceStatusAbrev: upperFirst(
          get(runner, "timeform.accBeatenDistanceStatusAbrev", "")
        ),
        winningDistance: upperFirst(
          get(runner, "timeform.winningDistance", "")
        ),
        winningDistanceStatus: upperFirst(
          get(runner, "timeform.winningDistanceStatus", "")
        )
      };

      raceIds.push(race.raceId);

      delete tempRace.raceId;
      delete tempRace.results;
      // @ts-ignore
      delete tempRace.type;
      // @ts-ignore
      delete tempRace.video;

      return tempRace;
    }),
    raceIds,
    isLoading: get(result, "data.loading", false),
    runnerName: get(result, "data.variables.runnerName"),
    runnerDob: get(result, "data.variables.runnerDob"),
    refetch: get(result, "data.refetch")
  };
};

// Used on MyBets, and converted to use useQuery
export const apolloOptionRacePP = ({
  wagerProfile,
  trackCode,
  raceNumber,
  raceDate,
  fcpClient,
  racesCounter
}: GraphFcpProps) => {
  const variables = {
    ...QUERY_VARIABLES_PP,
    wagerProfile,
    trackCode,
    raceNumber: `${raceNumber || ""}`,
    date: raceDate
  };

  return {
    client: fcpClient,
    fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
    pollInterval: 0,
    ssr: false,
    variables,
    skip: !trackCode || !raceNumber || !raceDate || !!racesCounter
  };
};

export const ApolloOptionsRacePP = {
  skip: (props: Props) =>
    !props.trackCode ||
    !props.raceNumber ||
    !props.raceDate ||
    get(props, "races.length", 0) !== 0,
  options: (props: GraphFcpProps) => {
    const variables = {
      ...QUERY_VARIABLES_PP,
      wagerProfile: props.wagerProfile,
      trackCode: props.trackCode,
      raceNumber: `${props.raceNumber || ""}`,
      date: props.raceDate
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 0,
      ssr: false,
      variables
    };
  },
  props: (data: {}) => {
    let resultsIpp = handleResultsIPP(data);
    if (resultsIpp) {
      resultsIpp = {
        ...resultsIpp,
        // @ts-ignore
        race: get(resultsIpp, "races[0]"),
        races: undefined
      };
    }
    return resultsIpp;
  }
};

// Used on MyBets, and converted to use useQuery
export const apolloOptionsGroupWagerPP = ({
  trackCode,
  raceNumber,
  raceDate,
  accountNumber,
  behgClient,
  behgToggle,
  myBetsResultedBetTicket
}: GraphBehgProps) => {
  const trackCodeFormatted =
    typeof trackCode === "string" ? trackCode.trim() : trackCode;
  const variables = {
    trackCode: trackCodeFormatted,
    raceNumber: raceNumber && isNumber(+raceNumber) ? +raceNumber : undefined,
    startDate: raceDate,
    endDate: raceDate,
    accountId: parseInt(`${accountNumber}`, 10)
  };

  return {
    client: behgClient,
    fetchPolicy: "cache-and-network" as WatchQueryFetchPolicy,
    pollInterval: 0,
    ssr: false,
    variables,
    skip:
      !accountNumber || !trackCode || !behgToggle || !myBetsResultedBetTicket
  };
};

export const ApolloOptionsWagerPP = {
  skip: (props: GraphBehgProps) =>
    !props.accountNumber ||
    !props.trackCode ||
    !props.behgToggle ||
    !props.myBetsResultedBetTicket ||
    !props.isAccountCompliant,
  options: (props: GraphBehgProps) => {
    const trackCode =
      typeof props.trackCode === "string"
        ? props.trackCode.trim()
        : props.trackCode;
    const variables = {
      trackCode,
      raceNumber:
        props.raceNumber && isNumber(+props.raceNumber)
          ? +props.raceNumber
          : undefined,
      startDate: props.raceDate,
      endDate: props.raceDate,
      accountId: parseInt(`${props.accountNumber}`, 10)
    };

    return {
      client: props.behgClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 0,
      ssr: false,
      variables
    };
  },
  props: (result: {}) => ({
    isPPLoading: get(result, "data.loading", false),
    queryGroupWagers: get(result, "data.wagerHistory.groupWagers", []).map(
      (groupWager: { value: string; wagers: string[] }) => ({
        value: groupWager.value.replace(/#/gi, "|"),
        wagers: (groupWager.wagers || []).filter(
          (wager: unknown) =>
            get(wager, "betStatus.code") !== "C" &&
            get(wager, "raceDate") === get(result, "ownProps.raceDate")
        )
      })
    )
  })
};

export const ApolloOptionsIPP = {
  options: (props: GraphFcpProps) => {
    const variables = {
      ...QUERY_VARIABLES_IPP,
      wagerProfile: props.wagerProfile || getPortByBrand(),
      runnerName: props.runnerName,
      runnerDob: props.runnerDob,
      entityRunnerId: props.entityRunnerId
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 0,
      ssr: false,
      variables
    };
  },
  props: handleResultsIPP
};

export const ApolloOptionsRunnerStats = {
  options: (props: GraphFcpProps) => {
    const variables = {
      ...QUERY_VARIABLES_RUNNER_STATS,
      wagerProfile: props.wagerProfile || getPortByBrand(),
      runnerName: props.runnerName,
      runnerDob: props.runnerDob,
      entityRunnerId: props.entityRunnerId
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 0,
      ssr: false,
      variables
    };
  },
  props: handleRunnerStats
};

const handleResultsPP = (result: {}) => ({
  pastRace: get(result, "data.pastRaces[0]", null),
  fullResults: mapRunnersToFullResults(
    get(result, "data.pastRaces[0].bettingInterests", []),
    get(result, "data.pastRaces[0].results.runners", [])
  ),
  isLoading: get(result, "data.loading", false),
  hasTimeformData: !!get(
    result,
    "data.pastRaces[0].results.runners[0].timeform",
    false
  )
});

export const ApolloOptionsPP = {
  options: (props: GraphFcpProps) => {
    const variables = {
      ...QUERY_VARIABLES_PP,
      wagerProfile: props.wagerProfile || getPortByBrand(),
      trackCode: props.trackCode,
      raceNumber: `${props.raceNumber || ""}`,
      date: props.raceDate
    };

    return {
      client: props.fcpClient,
      fetchPolicy: "cache-and-network",
      pollInterval: 0,
      ssr: false,
      variables
    };
  },
  props: handleResultsPP
};
