import styled, { css } from "styled-components";

export const OptionButton = styled.button<{ hasBorderBottom: boolean }>`
  min-height: 42px;
  background-color: var(--fd-colors-background-surface);
  padding: var(--fd-space-space-3);
  text-align: left;
  width: 100%;

  &:hover {
    background-color: var(--fd-colors-background-hover);
    cursor: pointer;
  }

  &:active {
    color: var(--fd-colors-component-data-input-label-active);
  }

  &:disabled {
    cursor: default;
    background-color: var(--fd-colors-content-disabled);
  }

  &[aria-selected="true"] {
    background-color: var(--fd-colors-background-hover);
    ${({ hasBorderBottom }) =>
      hasBorderBottom &&
      css`
        border-bottom: 1px solid var(--fd-colors-border-default);
      `}
    border-left: 2px solid var(--fd-colors-component-data-input-label-active);
  }
`;

export const StyledTextTitle = styled.p`
  color: var(--fd-colors-content-default);
  font-size: 14px;

  &:active {
    font-family: "Roboto-Medium", sans-serif;
    color: var(--fd-colors-component-data-input-label-active);
  }

  &[aria-selected="true"] {
    font-family: "Roboto-Medium", sans-serif;
    color: var(--fd-colors-component-data-input-label-active);
  }

  &[aria-disabled="true"] {
    font-family: "Roboto-Regular", sans-serif;
    color: var(--fd-colors-content-disabled);
  }
`;
