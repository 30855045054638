import { gql } from "@apollo/client";

export const GET_GROUP_WAGERS_SUBSCRIPTION_MY_BETS = gql`
  subscription GetGroupWagersSubscriptionMyBets(
    $accountId: Int!
    $statusList: [Status!]
    $trackCodes: [String!]
    $wagerTypes: [String!]
  ) {
    wagerHistoryUpdate(
      accountId: $accountId
      statusList: $statusList
      trackCodes: $trackCodes
      wagerTypes: $wagerTypes
    ) {
      id
      wagerTypeFilter {
        id
        code
        name
      }
      trackFilter {
        code
        name
      }
      totals(page: { current: 0, results: 99 }) {
        totalCount
        profit
        winnings
        refunded
        totalAmount
      }
      groupWagers(group: { group: RACE, order: DESC }) {
        id: key
        value: key
        wagers: value {
          id
          serialNumber
          wagerAmount
          betTotal: cost
          betRefund
          winningsAmount: winnings
          betStatus {
            code
            name
          }
          transactionDate
          wageringPlatform
          wagerType {
            id
            code
            name
          }
          promoQualifying
          cancelableStatus {
            code
            name
          }
          cancelable
          selections {
            selectionString
            modifier
            selection {
              bettingInterests {
                currentOdds {
                  numerator
                  denominator
                }
                biNumber
                isWinner
                runners {
                  runnerId
                  scratched
                  horseName
                }
                payouts {
                  amount
                  wagerType {
                    code
                  }
                }
              }
              selectionType {
                code
                name
              }
              runners {
                number
              }
            }
          }
          raceDate
          racePostTime: postTime
          raceTypeAbbreviation: raceType
          tvgRaceId
          trackName
          wagerReference
        }
      }
      futureWagersList {
        wagers {
          id
          serialNumber
          wagerAmount
          betTotal: cost
          betRefund
          winningsAmount: winnings
          betStatus {
            code
            name
          }
          transactionDate
          wageringPlatform
          wagerType {
            id
            code
            name
          }
          promoQualifying
          cancelableStatus {
            code
            name
          }
          cancelable
          selections {
            selectionString
            modifier
            selection {
              bettingInterests {
                currentOdds {
                  numerator
                  denominator
                }
                biNumber
                isWinner
                runners {
                  runnerId
                  scratched
                  horseName
                }
                payouts {
                  amount
                  wagerType {
                    code
                  }
                }
              }
              selectionType {
                code
                name
              }
              runners {
                number
              }
            }
          }
          raceDate
          racePostTime: postTime
          raceTypeAbbreviation: raceType
          tvgRaceId
          trackName
          wagerReference
          trackCode
          raceNumber
        }
      }
    }
  }
`;

export default GET_GROUP_WAGERS_SUBSCRIPTION_MY_BETS;
