import styled, { css } from "styled-components";

import {
  borderColor as bdColor,
  color,
  compose,
  space,
  width
} from "styled-system";
import { StyledInputProps } from "./types";

const borderColors = () => ({
  def: `var(--fd-colors-component-data-input-border-disabled)`,
  focused: `var(--fd-colors-component-data-input-border-active)`,
  invalid: `var(--fd-colors-component-data-input-border-error)`
});

const borderDefault = () => ({
  borderWidth: "1px",
  borderStyle: "solid",
  borderRadius: "4px",
  borderColor: borderColors().def
});

const cssBorder = ({
  isInvalid,
  isDisabled,
  isFocused
}: {
  isInvalid?: boolean;
  isDisabled?: boolean;
  isFocused?: boolean;
}) => {
  const { borderWidth, borderStyle, borderColor, borderRadius } =
    borderDefault();

  const { invalid, focused } = borderColors();
  const borderDefaultStyle = css`
    border: ${borderWidth} ${borderStyle} ${borderColor};
    border-radius: ${borderRadius};
  `;
  if (isDisabled) {
    return borderDefaultStyle;
  }

  if (isInvalid) {
    return css`
      ${borderDefaultStyle}
      border: 2px solid ${invalid};
    `;
  }

  if (isFocused) {
    return css`
      ${borderDefaultStyle}
      border: 2px solid ${focused};
    `;
  }

  return css`
    ${borderDefaultStyle}

    &:focus-within {
      border: 2px solid ${focused};
    }

    &:focus {
      border: 2px solid ${focused};
    }
  `;
};

const cssBoxShadow = ({ isDisabled }: { isDisabled?: boolean }) => {
  if (isDisabled) {
    return css`
      box-shadow: none;
    `;
  }
  return css`
    box-shadow: var(--fd-shadows-elevations-bottom-low);

    &:hover {
      box-shadow: var(--fd-shadows-elevations-bottom-mid);
    }
  `;
};

const cssBackground = ({ isDisabled }: { isDisabled?: boolean }) => {
  if (isDisabled) {
    return css`
      background-color: var(
        --fd-colors-component-data-input-background-disabled
      );
    `;
  }

  return css`
    background-color: var(--fd-colors-component-data-input-background-base);
  `;
};

const cssPlaceholder = ({ isDisabled }: { isDisabled: boolean }) => {
  if (isDisabled) {
    return css`
      &::placeholder {
        color: var(--fd-colors-core-transparent-black);
      }
    `;
  }

  return css`
    &::placeholder {
      color: var(--fd-colors-core-grey);
    }
  `;
};

const styledUtils = compose(space, width, color, bdColor);

export const InputBox = styled.div<{
  isDisabled?: boolean;
  hasBackground?: boolean;
  hasBorder?: boolean;
  isInvalid?: boolean;
  isFocused?: boolean;
}>`
  display: flex;
  flex-grow: 1;
  width: auto;
  height: 44px;
  flex-direction: row;
  align-content: space-between;
  align-items: center;
  ${({ isDisabled }) => cssBoxShadow({ isDisabled })};
  ${({ hasBorder, isDisabled, isInvalid, isFocused }) =>
    hasBorder && cssBorder({ isInvalid, isDisabled, isFocused })};
  ${({ hasBackground, isDisabled }) =>
    hasBackground && cssBackground({ isDisabled })};
  ${styledUtils};

  &:hover {
    ${({ isDisabled }) =>
      !isDisabled &&
      `color: var(--fd-colors-component-data-input-content-hover);
    background-color: var(--fd-colors-component-data-input-background-hover);
    border-color: var(--fd-colors-component-data-input-border-hover);`};
  }
`;

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  flex-grow: 1;
  width: 100%;
  height: 100%;
  background: transparent;
  border: none;
  ${({ isDisabled }) => cssPlaceholder({ isDisabled })};
  ${({ isDisabled }) => css`
    padding: 12px;
    font-family: "Roboto-Regular", sans-serif;
    font-size: 14px;
    color: ${isDisabled
      ? `var(--fd-colors-component-data-input-border-disabled)`
      : `var(--fd-colors-component-data-input-content-base)`};
  `}
`;

export const StyledPressable = styled.div<{
  isDisabled?: boolean;
  hasBackground?: boolean;
  hasBorder?: boolean;
  isInvalid?: boolean;
  isFocused?: boolean;
}>`
  display: flex;
  width: auto;
  height: 44px;
  flex-grow: 1;
  flex-shrink: 1;
  position: relative;
  flex-direction: row;
  align-content: space-between;
  ${({ isDisabled }) => cssBoxShadow({ isDisabled })};
  ${({ hasBorder, isDisabled, isInvalid }) =>
    hasBorder && cssBorder({ isInvalid, isDisabled })};
  ${({ hasBackground, isDisabled }) =>
    hasBackground && cssBackground({ isDisabled })};
  ${styledUtils};
`;

export const EndWrapper = styled.div`
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
`;

export const TopWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const LabelWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-content: flex-start;
  align-items: center;
`;

export const StyledLabel = styled.span`
  ${space};
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  color: var(--fd-colors-component-data-input-label-base);
`;

export const StyledOptionalTag = styled.span`
  margin-left: 4px;
  font-family: "Roboto-Medium", sans-serif;
  font-size: 12px;
  color: var(--fd-colors-content-subtle);
`;

export const InputWrapper = styled.div`
  ${space}
  ${width}
`;

export const IconWrapper = styled.div`
  margin-left: 4px;
`;

export const MessageWrapper = styled.div`
  ${space};
  margin-top: 12px;
  display: flex;
`;

export const SelectIconWrapper = styled.div<{
  isDisabled?: boolean;
}>`
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
  height: 32px;
  width: 32px;
`;

export const Link = styled.a`
  font-family: "Roboto-Medium", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 125%;
  text-decoration-line: none;
  color: var(--fd-colors-link-default-base);
  cursor: pointer;
  text-align: right;
`;
