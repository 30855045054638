import styled from "styled-components";

export const ButtonsContainer = styled.div`
  display: flex;
  border: 2px solid var(--fd-colors-component-button-tertiary-border-base);
  border-radius: 4px;
  overflow: hidden;
`;

export const NavButton = styled.button<{
  disabled: boolean;
}>`
  padding: 8px;
  background-color: var(--fd-colors-background-surface);
  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  & svg path {
    stroke: var(--fd-colors-component-button-tertiary-content-base);
  }

  &:hover {
    background-color: var(
      --fd-colors-component-button-tertiary-background-hover
    );

    & svg path {
      stroke: var(--fd-colors-component-button-tertiary-content-hover);
    }
  }

  &:active {
    background-color: var(
      --fd-colors-component-button-tertiary-background-active
    );

    & svg path {
      stroke: var(--fd-colors-component-button-tertiary-content-active);
    }
  }

  &:disabled {
    background-color: var(
      --fd-colors-component-button-tertiary-background-disabled
    );

    & svg path {
      stroke: var(--fd-colors-component-button-tertiary-content-disabled);
    }
  }
`;
