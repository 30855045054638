import styled, { css, keyframes } from "styled-components";
import { compose, space } from "styled-system";
import breakpoints from "../../../src/utils/breakpoints";

export const TOP_TRESHOLD = 40;

export const ANIMATION_DURATION = 400;

export const fadeIn = keyframes`
    from { opacity: 0.5; }
    to { opacity: 1; }
`;

const fadeOut = keyframes`
  from {
    opacity: 0.8;
  }
  to {
    opacity: 0;
  }
`;

const scaleUp = keyframes`
  from {
     transform: translate(-50%, 50%) scale(0.5);
      opacity: 0.5;
  }
  to {
     transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
`;

const slideUp = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0.5;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
`;

const slideDown = keyframes`
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0.5;
  }
`;

const scaleDown = keyframes`
   from {
    transform: translate(-50%, -50%) scale(1);
    opacity: 1;
  }
  to {
    transform: translate(-50%, 50%) scale(0.5);
    opacity: 0;
  }
`;

const slideLeft = keyframes`
  from { transform: translateX(-70%); }
  to { transform: translateX(0); }
`;

const slideRight = keyframes`
  from { transform: translateX(70%); }
  to { transform: translateX(0); }
`;

const styles = compose(space);

export const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;

  &[data-hide-overlay="true"] {
    background: inherit;
  }

  &[data-hide-overlay="false"] {
    background: var(--fd-colors-core-black-tint-070);
  }

  &[data-closing="true"] {
    ${css`
      animation: ${fadeOut} ${ANIMATION_DURATION}ms ease-out;
    `};
    opacity: 0;
    pointer-events: none;
  }

  &[data-closing="false"] {
    ${css`
      animation: ${fadeIn} ${ANIMATION_DURATION}ms ease-out;
    `};
    opacity: 1;
    pointer-events: auto;
  }
`;
export const ModalContainer = styled.div<{
  maxHeight?: string;
  hasMaxHeight?: boolean;
  maxWidth?: string;
  overrideInset?: number;
}>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: fixed;
  background: var(--fd-colors-background-surface);
  z-index: 9999;
  overflow: hidden;

  @media screen and ${breakpoints.tablet.min.sm} {
    height: ${({ hasMaxHeight, maxHeight }) =>
      hasMaxHeight ? `${maxHeight} || 98vh` : "auto"};
    max-height: ${({ maxHeight }) => maxHeight || "98vh"};
    max-width: ${({ maxWidth }) => maxWidth || "375px"};
    width: 90%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    border-radius: 4px;
    box-shadow: var(--fd-shadows-elevations-all-around-high);

    &[data-closing="true"] {
      ${css`
        animation: ${scaleDown} ${ANIMATION_DURATION}ms
          cubic-bezier(0.25, 1, 0.5, 1);
      `};
    }

    &[data-closing="false"] {
      ${css`
        animation: ${scaleUp} ${ANIMATION_DURATION}ms
          cubic-bezier(0.25, 1, 0.5, 1);
      `};
    }
  }

  @media screen and ${breakpoints.tablet.max.sm} {
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 8px 8px 0 0;
    box-shadow: var(--fd-shadows-elevations-top-low);

    &[data-closing="true"] {
      ${css`
        animation: ${slideDown} ${ANIMATION_DURATION}ms
          cubic-bezier(0.25, 1, 0.5, 1);
      `};
    }

    &[data-closing="false"] {
      ${css`
        animation: ${slideUp} ${ANIMATION_DURATION}ms
          cubic-bezier(0.25, 1, 0.5, 1);
      `};
    }

    ${({ hasMaxHeight, overrideInset, maxHeight }) =>
      hasMaxHeight
        ? css`
            height: calc(
              ${maxHeight || "100vh"} -
                ${overrideInset !== undefined ? overrideInset : TOP_TRESHOLD}px
            );

            ${overrideInset === 0 && "border-radius: 0;"}
          `
        : css`
            height: auto;
            max-height: 100vh;
          `}
  }
`;

export const ModalHeader = styled.div`
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const ModalContent = styled.div<{
  hasMaxHeight?: boolean;
  hasPadding?: boolean;
}>`
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;

  @-moz-document url-prefix() {
    width: 100%;
  }

  ${({ hasPadding }) =>
    hasPadding
      ? css`
          padding: 12px;
        `
      : css`
          padding: 0;
        `}

  ${({ hasMaxHeight }) =>
    hasMaxHeight
      ? css`
          height: 100%;
          flex: 1;
        `
      : css`
          height: auto;
        `}
  ${styles}
`;

export const ModalFooter = styled.div`
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: var(--fd-colors-background-surface);
  padding: 0 12px 12px;

  @media screen and ${breakpoints.tablet.max.sm} {
    position: sticky;
  }
`;

export const TopStickyArea = styled.div`
  position: sticky;
  top: 0;
  z-index: 9999;
  width: 100%;
`;

export const ModalHeaderContainer = styled.div`
  width: 100%;
  overflow: hidden;
  display: flex;
`;

export const PagesSlider = styled.div`
  padding: 12px;
  height: 100%;
  overflow: hidden;
  ${styles}

  &[data-slide="right"] {
    ${css`
      animation: ${slideRight} ${ANIMATION_DURATION}ms forwards;
    `};
  }

  &[data-slide="left"] {
    ${css`
      animation: ${slideLeft} ${ANIMATION_DURATION}ms forwards;
    `};
  }
`;
