import React from "react";

import { Icon } from "../../../Icon";
import { Button } from "../styled-components";

import type { CloseButtonProps } from "./types";

export const CloseButton = ({ onClose, ...rest }: CloseButtonProps) => (
  <Button onClick={onClose} {...rest}>
    <Icon size="m" lineColor="--fd-colors-content-default" name="close" />
  </Button>
);
