import { getSession } from "@urp/unified-modules/src/utils/account";
import mediator from "@tvg/mediator";
import { isFDR } from "@tvg/utils/generalUtils";

export const openLoginWall = (
  loginSuccessCallback: () => void,
  loginFailCallback?: () => void
) => {
  if (isFDR()) {
    getSession("Normal", loginSuccessCallback);
  } else {
    mediator.base.dispatch({
      type: "TVG_LOGIN:OPEN_LOGIN_MODAL",
      payload: {
        callback: (data?: { status: string }) => {
          if (
            !data ||
            data.status !== "error" ||
            typeof loginSuccessCallback === "function"
          ) {
            loginSuccessCallback();
          } else if (typeof loginFailCallback === "function") {
            loginFailCallback();
          }
        }
      }
    });
  }
};
