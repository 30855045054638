import styled from "styled-components";
import type { SelectProps } from "../types";

export const BoxWrapper = styled.div`
  position: relative;
`;

export const Select = styled.select<Partial<SelectProps>>`
  appearance: none;
  height: 42px;
  background-color: transparent;
  border: transparent;
  z-index: 1;
  padding-left: 12px;
  padding-right: 36px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Roboto-Regular", sans-serif;
  font-size: 14px;
  color: ${({ disabled, value, placeholder }) =>
    disabled || (!value && placeholder)
      ? `var(--fd-colors-core-transparent-black)`
      : `var(--fd-colors-core-grey-d-4)`};
`;

export const Option = styled.option`
  font-family: "Roboto-Regular", sans-serif;
`;

export const SelectWrapper = styled.div`
  margin-top: 16px;
  padding: 12px;
  width: 100%;
  box-shadow: var(--fd-shadows-elevations-bottom-low);
  border-color: blue;
  border-radius: 2px;
`;
