import { sortBy, get } from "lodash";
import { format } from "date-fns";
import type { UserOptedInPromos } from "@tvg/ts-types/User";
import {
  Race,
  RaceStatusEnum,
  RaceTypeCodeEnum,
  Promo,
  RaceDistanceCodeEnum,
  PastRaceResults,
  ResultRunner,
  RaceProgram as RaceProgramPP
} from "@tvg/ts-types/Race";
import type { TopPool } from "@tvg/ts-types/Pool";
import type { SeoTrack, Track } from "@tvg/ts-types/Track";
import { RaceProgram } from "@tvg/lhn/src/types";
import { CPPPromo } from "@urp/quick-links/src/types";
import {
  FilterOptions,
  HorseResultEntity,
  RegionFiltersEnum,
  TrackFilterValueEnum
} from "../types";

export const getTrackName = (trackName: string, delimiter = " - ") => {
  const indexOfDelimiter = trackName.indexOf(delimiter);
  return indexOfDelimiter > 0
    ? trackName.slice(indexOfDelimiter + delimiter.length)
    : trackName;
};

export const findTrack = (
  tracks: Array<Track>,
  trackCode: string | undefined
) => tracks.find((track) => track.code === trackCode);

export const getRaceCacheId = (
  trackCode: string | undefined,
  raceNumber: string | undefined,
  date: string | undefined
) => `${trackCode}-${raceNumber}-${date}`;

export const filterTracksByRegion = (
  tracks: Array<SeoTrack | Track>,
  regionsFilter: Array<string>
): Array<SeoTrack | Track> =>
  regionsFilter.length > 0
    ? tracks.filter((track) =>
        isRegionIncluded(
          regionsFilter,
          track.location?.country || RegionFiltersEnum.International
        )
      )
    : tracks;

export const filterRacesByRegion = (
  races: Race[],
  regionsFilter: Array<string>
): Race[] =>
  regionsFilter.length > 0
    ? races.filter((race) =>
        isRegionIncluded(
          regionsFilter,
          race.track.location?.country || RegionFiltersEnum.International
        )
      )
    : races;

export const filterRacesByTrackCode = (
  races: Array<Race>,
  trackCodes: Array<string>
) =>
  trackCodes.length > 0
    ? races.filter((race) => trackCodes.includes(race.track.code))
    : races;

export const filterRacesByRaceType = (
  races: Array<Race>,
  raceTypesFilter: Array<RaceTypeCodeEnum>
) =>
  raceTypesFilter.length > 0
    ? races.filter((race) => raceTypesFilter.includes(race.type.code))
    : races;

export const filterRacesByStatus = (
  races: Array<Race>,
  raceStatusFilter: Array<RaceStatusEnum>
) =>
  raceStatusFilter.length > 0
    ? races.filter((race) => raceStatusFilter.includes(race.status.code))
    : races;

export const isRegionIncluded = (regions: Array<string>, region: string) =>
  regions.includes(region) ||
  (regions.includes(RegionFiltersEnum.International) &&
    region !== RegionFiltersEnum.US);

export const isTrackOpen = (openTracks: string[], trackCode: string): boolean =>
  openTracks.includes(trackCode);

export const getOpenTracksRaces = (
  openTracks: Array<string>,
  tracks: Array<Track>,
  races: Array<Race>
): Array<Track> =>
  tracks.map((track) =>
    openTracks.includes(track.code)
      ? {
          ...track,
          races: races.filter((race) => race.track.code === track.code)
        }
      : track
  );

export const filterTracksWithRaces = (
  tracks: Array<Track>,
  races: Array<Race>
) =>
  tracks.filter((track) =>
    races.some((race) => race.track.code === track.code)
  );

export const getUpcomingRaces = (
  allUpcomingRaces: Array<Race>,
  maxResults: number
) => sortBy(allUpcomingRaces, ["mtp", "track.name"]).slice(0, maxResults);

export const getRacesWithTrackLocation = (
  races: Array<Race>,
  tracks: Array<Track>
) =>
  races.map((race) => {
    const track = findTrack(tracks, race.track.code);

    return {
      ...race,
      track: {
        ...race.track,
        hasAboveTheLinePromo: !!track?.hasAboveTheLinePromo,
        name: race.track.name,
        location: {
          country: track?.location?.country || "",
          state: track?.location?.state || ""
        }
      }
    };
  });

export const getRacePromos = (race: Race) =>
  race.promos?.filter((promo) => promo.isPromoTagShown) ?? [];

export const hasPromoAvailable = (race: Race): Promo[] =>
  race.promos?.filter((promo) => promo.isAboveTheLine) ?? [];

export const isUserOptedInPromo = (
  optedInPromos: UserOptedInPromos,
  promos: Promo[]
) => promos.some((promo) => get(optedInPromos, promo.rootParentPromoID, false));

const isCPPPromoToday = (cardDates?: string[]) => {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  return cardDates ? cardDates.includes(todayDate) : true;
};

export const hasTrackPromoCPP = (cppPromos: CPPPromo[], track: Track) =>
  cppPromos.some(
    (cppPromo) =>
      cppPromo.bettingRules?.trackCodes?.includes(track.code) &&
      isCPPPromoToday(cppPromo.bettingRules?.cardDates)
  );

const getRaceInfo = (race: Race | RaceProgram) => {
  const [raceCode, raceNumber] = race.id.split("-");
  return { raceCode, raceNumber };
};

export const hasRacePromoCPP = (
  cppPromos: CPPPromo[],
  race: Race | RaceProgram
) => {
  const { raceCode, raceNumber } = getRaceInfo(race);

  return cppPromos.some(
    (cppPromo) =>
      cppPromo.bettingRules?.trackCodes?.includes(raceCode) &&
      cppPromo.bettingRules?.raceNumbers?.includes(raceNumber) &&
      isCPPPromoToday(cppPromo.bettingRules?.cardDates)
  );
};

export const getBetTypePromosCPP = (
  cppPromos: CPPPromo[],
  race: Race | RaceProgram | RaceProgramPP
) => {
  const trackCodes = race?.id?.split("-")[0];
  const raceNumber = race?.id?.split("-")[1];
  return cppPromos
    .filter(
      (promo) =>
        promo.bettingRules?.trackCodes?.includes(trackCodes as string) &&
        promo.bettingRules?.raceNumbers?.includes(raceNumber as string) &&
        isCPPPromoToday(promo.bettingRules?.cardDates)
    )
    .flatMap((promo) => promo.bettingRules?.racingBetTypes);
};

export const hasRacePromoCPPOptedIn = (
  cppPromos: CPPPromo[],
  race: Race | RaceProgram,
  isLogged: boolean
) => {
  const { raceCode, raceNumber } = getRaceInfo(race);

  return cppPromos.some(
    (cppPromo) =>
      cppPromo.bettingRules?.trackCodes?.includes(raceCode) &&
      cppPromo.bettingRules?.raceNumbers?.includes(raceNumber) &&
      cppPromo.isOptedIn &&
      isCPPPromoToday(cppPromo.bettingRules?.cardDates) &&
      isLogged
  );
};

export const hasRacePromoCPPSameType = (
  cppPromos: CPPPromo[],
  race: Race | RaceProgram,
  betType: string
) => {
  const { raceCode, raceNumber } = getRaceInfo(race);

  return cppPromos.some(
    (cppPromo) =>
      cppPromo.bettingRules?.trackCodes?.includes(raceCode) &&
      cppPromo.bettingRules?.raceNumbers?.includes(raceNumber) &&
      cppPromo.bettingRules?.racingBetTypes?.includes(betType) &&
      isCPPPromoToday(cppPromo.bettingRules?.cardDates)
  );
};

export const getCurrentCPPPromo = (
  cppPromos: CPPPromo[],
  race: Race | RaceProgram
) => {
  const { raceCode, raceNumber } = getRaceInfo(race);

  return cppPromos.find(
    (cppPromo) =>
      cppPromo.bettingRules?.trackCodes?.includes(raceCode) &&
      cppPromo.bettingRules?.raceNumbers?.includes(raceNumber) &&
      isCPPPromoToday(cppPromo.bettingRules?.cardDates)
  );
};

export const getOptedInCPPPromos = (cppPromos: CPPPromo[]) =>
  cppPromos.reduce((acc: { [key: string]: boolean }, cppPromo) => {
    acc[cppPromo.promoId] = cppPromo.isOptedIn;
    return acc;
  }, {});

export const filterTrackByTrackType = (
  tracks: Track[],
  filter: TrackFilterValueEnum[],
  favoriteTracks: string[]
) =>
  tracks.filter((track) => {
    const isFavoriteTrack =
      favoriteTracks.includes(track.code) || track.isFavorite;
    const isFeaturedTrack = track.featured;
    const filterIncludesFeatured = filter.includes(
      TrackFilterValueEnum.FEATURED
    );
    const filterIncludesFavorites = filter.includes(
      TrackFilterValueEnum.FAVORITE
    );

    return (
      (filterIncludesFeatured && isFeaturedTrack) ||
      (filterIncludesFavorites && isFavoriteTrack) ||
      (filterIncludesFeatured &&
        filterIncludesFavorites &&
        isFeaturedTrack &&
        isFavoriteTrack)
    );
  });

export const filterRacesByDistanceInterval = (
  races: Race[],
  filters: string[]
) =>
  races.filter((race) => {
    const distance = race.raceDistance?.value;

    if (
      race.raceDistance?.code !== RaceDistanceCodeEnum.FURLONG ||
      distance === undefined
    )
      return false;

    return filters.some((filter) => {
      const [lowFilter, highFilter] = filter.split("-");

      const lowValue = Number.parseInt(lowFilter, 10);
      const highValue = Number.parseInt(highFilter, 10);

      if (
        !Number.isNaN(lowValue) &&
        Number.isNaN(highValue) &&
        distance >= lowValue
      )
        return true;
      if (
        Number.isNaN(lowValue) &&
        !Number.isNaN(highValue) &&
        distance < highValue
      )
        return true;
      if (
        !Number.isNaN(lowValue) &&
        !Number.isNaN(highValue) &&
        distance >= lowValue &&
        distance < highValue
      )
        return true;
      return false;
    });
  });

export const filterRacesByRaceAttribute = (
  races: Race[],
  filters: string[],
  topPools: TopPool[] = []
) =>
  races.filter((race) => {
    const availableRacePromos = hasPromoAvailable(race);

    const isPartOfTopPools = topPools.find(
      (topPool) =>
        topPool.trackCode === race.track.code &&
        topPool.race.raceNumber === race.raceNumber
    );

    return (
      ((race.video.onTvg || race.video.onTvg2) && filters.includes("fdtv")) ||
      (!!availableRacePromos.length && filters.includes("promos")) ||
      (isPartOfTopPools && filters.includes("topPools"))
    );
  });

export const hasFiltersApplied = (
  filters: FilterOptions = {},
  includeSearch: boolean = false
) =>
  Object.keys(filters).reduce(
    (acc, key) => acc || get(filters, key, []).length > 0,
    false
  ) || !!(includeSearch && !!filters.tracksSearchQuery);

export const getDupsHorseResults = (horseResults: HorseResultEntity[]) => {
  if (horseResults?.length === 0) return [];

  const horseResultCounter = horseResults.reduce((acc, { name }) => {
    const value = get(acc, name);
    if (get(acc, name)) {
      return { ...acc, [name]: value + 1 };
    }
    return { ...acc, [name]: 1 };
  }, {});

  return Object.keys(horseResultCounter).filter(
    (key) => get(horseResultCounter, key) > 1
  );
};

export const filterAndSortRunners = (
  races: Array<PastRaceResults>
): Array<PastRaceResults> =>
  races.map((race: PastRaceResults) => {
    if (race.results?.allRunners) {
      const filteredRunners = race.results.allRunners
        .filter((runner: ResultRunner) => !!runner.finishPosition)
        .sort(
          (a: ResultRunner, b: ResultRunner) =>
            a.finishPosition - b.finishPosition
        );
      return {
        ...race,
        results: { ...race.results, runners: filteredRunners }
      };
    }
    return race;
  });
