import styled, { css } from "styled-components";
import {
  BetTypesButton,
  BetTypesText
} from "../../BetTypeButton/styled-components";

export const BetTypesButtonDropdown = styled(BetTypesButton)`
  border-bottom: var(--fd-border-widths-border-width-010) solid
    var(--fd-colors-border-default);

  &:hover {
    border-bottom-color: var(--fd-colors-border-default);
    ${({ isSelected }) =>
      isSelected &&
      css`
        border-bottom-width: var(--fd-border-widths-border-width-020);
        border-bottom-color: var(--fd-colors-content-link);
      `};
  }

  ${({ isSelected }) =>
    isSelected &&
    css`
      border-bottom-width: var(--fd-border-widths-border-width-020);
      border-bottom-color: var(--fd-colors-content-link);
    `};
`;

export const BetTypesTextDropdown = styled(BetTypesText)`
  text-align: left;
`;
