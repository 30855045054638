import {
  UMEventsAccount,
  UMEventsAmplitude,
  UMEventsGeneric,
  UMEventsWallet
} from "./types";

export const getUMEvent = (
  event: UMEventsAccount | UMEventsWallet | UMEventsAmplitude | UMEventsGeneric
) => ({
  trigger: event,
  completion: `${event}_COMPLETE`
});
