import { isArray, omitBy } from "lodash";
import { getUMEvent } from "../events";
import type { Events } from "./types";
import type { UMEventsAccount, UMEventsWallet } from "../types";

export const formatEvents = (events: Events) =>
  Object.entries(events).reduce(
    (eventsAcc, currentEvent) => {
      const [eventKey, mapEventValue] = currentEvent;
      let eventTrigger;
      let eventCompletion;

      if (typeof mapEventValue === "string") {
        const eventFormatted = getUMEvent(
          mapEventValue as UMEventsAccount | UMEventsWallet
        );
        eventTrigger = eventFormatted.trigger;
        eventCompletion = eventFormatted.completion;
      } else if (isArray(mapEventValue)) {
        eventTrigger = mapEventValue[0];
        eventCompletion = mapEventValue[1];
      } else {
        throw new Error("[UM]: Wrong configuration on UM events");
      }

      const eventsWithCompletion = omitBy(
        {
          [eventKey]: eventTrigger,
          [`${eventKey}_COMPLETE`]: eventCompletion
        },
        (event) => event === ""
      );

      return {
        triggers: {
          ...eventsAcc.triggers,
          ...eventsWithCompletion
        },
        subscriptions: {
          ...eventsAcc.subscriptions,
          ...eventsWithCompletion
        }
      };
    },
    {
      triggers: {},
      subscriptions: {}
    }
  );
