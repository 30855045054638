import React, { type FC } from "react";

import { Header, Paragraph } from "../Typography";
import { Icon } from "../Icon";
import { BackButton } from "./components/BackButton";
import { CloseButton } from "./components/CloseButton";
import {
  HeaderContainer,
  HeaderSection,
  ActionContainer,
  Wrapper,
  LinkWrapper,
  Link
} from "./styled-components";

import type { PageHeaderProps, PageHeaderSize } from "./types";

export const PageHeader: FC<PageHeaderProps> = ({
  title,
  description,
  showBackButton = false,
  showCloseButton = false,
  onBack,
  onClose,
  linkLabel = "Link",
  linkUrl,
  showLink = false,
  qaLabel = "page-header",
  saddle,
  size = "medium",
  ...rest
}) => (
  <HeaderContainer data-qa-label={qaLabel} size={size} {...rest}>
    <ActionContainer>
      {showBackButton && (
        <BackButton data-qa-label={`${qaLabel}-back-button`} onBack={onBack} />
      )}
    </ActionContainer>
    <HeaderSection>
      <Wrapper>
        {saddle}
        <Header
          p={0}
          m={0}
          tag="h3"
          color="--fd-colors-content-strong"
          fontSize={18}
          textAlign="center"
          width="max-content"
          data-qa-label={`${qaLabel}-title`}
        >
          {title}
        </Header>
      </Wrapper>
      {description && !showLink && (
        <Paragraph
          p={0}
          m={0}
          fontFamily="regular"
          color="--fd-colors-content-subtle"
          fontSize={14}
          lineHeight="125%"
          textAlign="center"
          width="max-content"
          data-qa-label={`${qaLabel}-description`}
        >
          {description}
        </Paragraph>
      )}
      {showLink && (
        <LinkWrapper>
          <Icon
            size="s"
            lineColor="--fd-colors-system-info-content-accent"
            backgroundColor="--fd-colors-system-info-background-subtle"
            name="info"
            data-qa-label={`${qaLabel}-icon-info`}
          />
          <Link href={linkUrl} data-qa-label={`${qaLabel}-link`}>
            {linkLabel}
          </Link>
        </LinkWrapper>
      )}
    </HeaderSection>
    <ActionContainer>
      {showCloseButton && (
        <CloseButton
          data-qa-label={`${qaLabel}-close-button`}
          onClose={onClose}
        />
      )}
    </ActionContainer>
  </HeaderContainer>
);

export type { PageHeaderProps, PageHeaderSize };
