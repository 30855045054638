import { Dispatch } from "redux";
import { get, set } from "lodash";
import type { Balance } from "@fanduel/wallet-contract";
import {
  failBalanceRequest,
  updateWalletBalances
} from "@tvg/shared-actions/UserActions";
import * as mediatorClassic from "@tvg/mediator-classic/src";
import { loginSuccessGtm } from "../../../../../../../utils/account/gtm";

const balanceMap = {
  RACING_PLAYABLE: "racingPlayable",
  USER_RACING_BONUS: "racingBonus",
  RACING_OTHER_CASH: "sharedDeposits",
  SHARED_WINNINGS: "sharedWinnings"
};

export const setRacingWallet = (
  balances: Set<Balance> | null,
  dispatch: Dispatch,
  accountNumber?: string
) => {
  let balancePlayable: number | undefined;

  if (balances) {
    const racingWalletBalances = {};

    balances.forEach((wallet) => {
      const walletKey = get(balanceMap, wallet.accountType);

      if (walletKey) {
        set(racingWalletBalances, walletKey, wallet.balance);
      }
    });

    balancePlayable = get(racingWalletBalances, balanceMap.RACING_PLAYABLE);

    dispatch(
      updateWalletBalances(
        get(racingWalletBalances, "racingPlayable"),
        get(racingWalletBalances, "racingBonus"),
        get(racingWalletBalances, "sharedDeposits"),
        get(racingWalletBalances, "sharedWinnings")
      )
    );

    mediatorClassic.dispatch("BALANCE_UPDATE", balancePlayable);
  } else {
    dispatch(failBalanceRequest());
  }

  // TODO: Move this because is in wrong place.
  if (accountNumber) {
    loginSuccessGtm(accountNumber, balancePlayable);
  }
};
