import type { MarginProps } from "styled-system";

export type Brand = "4njbets" | "iowa" | "tvg" | "fdr" | "fdr-iowa";

export interface LogoProps extends MarginProps {
  /**
   * Set custom height
   * @default 44
   */
  height?: number;
  /**
   * Brand  "tvg" | "4njbets" | "iowa" | "fdr" | "fdr-iowa"
   * @default tvg
   */
  brand?: Brand;
  /**
   * Set aria-label
   */
  accessibilityLabel?: string;
  /**
   * Custom data-qa-label
   */
  qaLabel?: string;
}

export enum BRAND_WIDTH {
  "tvg" = 145,
  "fdr" = 154,
  "iowa" = 151,
  "4njbets" = 205,
  "fdr-iowa" = 210
}

export enum LOGO_PATH {
  "tvg" = "Tvg",
  "fdr" = "Fdr",
  "iowa" = "Iowa",
  "fdr-iowa" = "FdrIowa",
  "4njbets" = "FourNjBets"
}
