import { get } from "lodash";
import tvgConf from "@tvg/conf";
import requester from "@tvg/api/requester";
import {
  WalletData,
  UserJurisdictionsData,
  UsersFanduel,
  WalletDataRow
} from "./types";

const { config, getClientId } = tvgConf();
const baseURL = config("externalServices.fanduel");
const clientId = getClientId();

export const getWalletBalances = (authToken: string) =>
  requester()({
    baseURL,
    url: "/account/wallet",
    method: "get",
    withCredentials: true,
    headers: {
      "X-Auth-Token": authToken,
      Authorization: `Basic ${clientId}`
    }
  }).then(
    (result: { data: { wallet_balances: WalletData } }) =>
      get(result, "data.wallet_balances", []) as WalletDataRow[]
  );

export const getUserJurisdictions = (
  authToken: string,
  userId: string,
  jurisdiction: string
): Promise<UserJurisdictionsData> =>
  requester()({
    baseURL,
    url: `/users/${userId}/jurisdictions/${jurisdiction}/product/RACING`,
    method: "get",
    withCredentials: true,
    headers: {
      "X-Auth-Token": authToken,
      Authorization: `Basic ${clientId}`
    }
  }).then((result: { data: { user_jurisdictions: UserJurisdictionsData } }) =>
    get(result, "data.user_jurisdictions")
  );

export const getFanduelUserProfile = (
  authToken: string
): Promise<UsersFanduel> =>
  requester()({
    baseURL,
    url: `/users/current`,
    method: "get",
    withCredentials: true,
    headers: {
      "X-Auth-Token": authToken,
      Authorization: `Basic ${clientId}`
    }
  }) as unknown as Promise<UsersFanduel>;
