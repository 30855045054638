import { UMEvents } from "../../types";
import type { Events } from "../types";

export default {
  // Account
  [UMEvents.OpenSettings]: `UM_ACCOUNT_OPEN_SETTINGS`,
  [UMEvents.FirstLoad]: `UM_ACCOUNT_FIRST_LOAD`,
  [UMEvents.KeepAlive]: `UM_ACCOUNT_KEEP_ALIVE`,
  [UMEvents.Logout]: `UM_ACCOUNT_LOGOUT`,
  [UMEvents.Session]: `UM_ACCOUNT_SESSION`,
  [UMEvents.SessionUpdate]: `UM_ACCOUNT_SESSION_UPDATE`,
  [UMEvents.Verification]: `UM_ACCOUNT_VERIFICATION`,
  [UMEvents.AcceptTerms]: `UM_ACCOUNT_ACCEPT_TERMS`,
  // Generic
  [UMEvents.PresentModal]: `UM_ACCOUNT_PRESENT_MODAL`,
  // Wallet
  [UMEvents.GetBalance]: `UM_ACCOUNT_GET_BALANCE`,
  [UMEvents.Deposit]: `UM_ACCOUNT_DEPOSIT`,
  [UMEvents.Tax]: `UM_ACCOUNT_TAX`,
  [UMEvents.Transactions]: `UM_ACCOUNT_TRANSACTIONS`,
  [UMEvents.Withdraw]: `UM_ACCOUNT_WITHDRAW`,
  [UMEvents.Activity]: `UM_ACCOUNT_ACTIVITY`,
  [UMEvents.AccountHome]: `UM_ACCOUNT_ACCOUNT_HOME`,
  // Amplitude
  [UMEvents.SetSessionId]: "UM_SET_SESSION_ID",
  [UMEvents.SetDeviceId]: "UM_SET_DEVICE_ID"
} as Events;
