import { createElement } from "react";
import styled from "styled-components";
import {
  space,
  system,
  compose,
  fontFamily,
  fontSize,
  flex,
  layout,
  typography,
  variant,
  color
} from "styled-system";
import { HeaderProps } from "./types";

const systemProps = system({
  textTransform: {
    property: "textTransform"
  },
  whiteSpace: {
    property: "whiteSpace"
  },
  textOverflow: {
    property: "textOverflow"
  }
});

const styles = compose(
  color,
  space,
  systemProps,
  typography,
  fontFamily,
  fontSize,
  layout,
  variant({
    prop: "tag",
    scale: "headingSizes"
  })
);

export const StyledHeader = styled(({ tag, gap, ...props }) =>
  createElement(tag || "p", props)
).attrs<HeaderProps>(({ tag, textOverflow }) => ({
  accessibilityLevel: tag && tag.replace("h", ""),
  overflow: textOverflow === "ellipsis" ? "hidden" : "visible"
}))<HeaderProps>`
  ${styles}
  ${flex}
  ${({ gap }) => gap && `gap: ${gap};`} /* Conditionally apply gap */
`;
