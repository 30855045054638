import React, { type FC } from "react";

import { Icon, type IconNames } from "../Icon";
import { TagContainer, TagLabel } from "./styled-components";

import { TAG_THEME_VARIANTS } from "./style.themes";

import type { TagProps } from "./types";

export const Tag: FC<TagProps> = ({
  variant,
  size = "medium",
  label,
  iconName,
  qaLabel = "tag",
  hideIcon = false,
  isEllipsed = false,
  ...props
}) => (
  <TagContainer
    data-qa-label={`${qaLabel}`}
    variant={variant}
    size={size}
    label={label}
    {...props}
  >
    {size !== "small" && !hideIcon && variant !== "favorite" && (
      <Icon
        size="s"
        lineColor={TAG_THEME_VARIANTS[variant as TagProps["variant"]].color}
        name={
          iconName ||
          (TAG_THEME_VARIANTS[variant as TagProps["variant"]]
            .iconName as IconNames)
        }
      />
    )}
    {label && (
      <TagLabel
        data-qa-label={`${qaLabel}-label`}
        variant={variant}
        size={size}
        isEllipsed={isEllipsed}
      >
        {label}
      </TagLabel>
    )}
  </TagContainer>
);

export type { TagProps };
