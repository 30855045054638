// @ts-ignore
import { createElement, View } from "react-native";
import styled from "styled-components/native";

import type { OptionsProps, SelectProps } from "../types";

export const SelectElement = (props: SelectProps) =>
  createElement("select", props);

export const OptionElement = (props: OptionsProps) =>
  createElement("option", props);

export const Select = styled(SelectElement)<
  Pick<SelectProps, "fonts" | "fontsizes" | "colors">
>`
  appearance: none;
  height: 42px;
  background-color: transparent;
  border: transparent;
  z-index: 1;
  padding-left: 12px;
  padding-right: 36px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: ${({ fonts }) => fonts.regular};
  font-size: ${({ fontsizes }) => fontsizes.s};
  color: ${({ disabled, value, placeholder }) =>
    disabled || (!value && placeholder)
      ? "var(--fd-colors-component-data-input-content-disabled)"
      : "var(--fd-colors-component-data-input-content-base)"};
`;

export const Option = styled(OptionElement)<OptionsProps>`
  font-family: ${({ fonts }) => fonts.regular};
`;

export const SelectWrapper = styled(View)`
  margin-top: var(--fd-space-space-4);
  padding: var(--fd-space-space-3);
  width: 100%;
  box-shadow: 0 2px 2px rgba(17, 43, 68, 0.15);
  border-color: blue;
  border-radius: 2px;
`;

export const SelectIconWrapper = styled(View)<{
  isDisabled: boolean;
  isMobile?: boolean;
}>`
  position: absolute;
  align-items: center;
  justify-content: center;
  right: 5px;
  ${({ isMobile }) =>
    isMobile &&
    `
     top: calc(var(--fd-space-space-2)-2);
   `};
  border-radius: 100px;
  height: var(--fd-space-space-8);
  width: var(--fd-space-space-8);
`;

export const RnSelectWrapper = styled(View)`
  align-items: center;
  justify-content: center;
  padding-left: var(--fd-space-space-3);
  flex-grow: 1;
  height: 42px;
`;
