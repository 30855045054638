import {
  RaceInfoMyBetsResults,
  RaceInfoMyBets,
  RaceVideoFeedback,
  RaceStatusEnum,
  RaceInfoMyBetsRunner,
  RaceLegStatus,
  RaceTypeCodeEnum
} from "@tvg/ts-types/Race";
import { useSelector } from "react-redux";
import { get, first, find } from "lodash";
import { isBefore } from "date-fns";
import { CheckRaceStatus } from "@tvg/sh-lib-my-bets/utils/types";
import { WagerTypeCodesEnum } from "@tvg/ts-types/Wager";
import { WroWagerGroup } from "@tvg/ts-types/WroWager";
import { Selections, SelectionRunner } from "@tvg/ts-types/Selections";
import { getXsellBlacklistedMyBets } from "../redux/selectors";

type Props = {
  isMultiRace: boolean;
  isSettledBet: boolean;
  isCancelled: boolean;
  legRaces: Array<RaceInfoMyBets>;
  currentRace?: RaceInfoMyBets;
  currentLeg?: RaceInfoMyBets;
  bet: WroWagerGroup;
  currentRaceDate: string;
  betDate: string;
  videoFeedback: RaceVideoFeedback;
  raceNumber: number;
};
const betTypesCodes = {
  exotic: [
    "EX",
    "EKB",
    "EXB",
    "EXW",
    "EXK",
    "TR",
    "TRB",
    "TRW",
    "TRK",
    "TKB",
    "SU",
    "SUB",
    "SUK",
    "SUW",
    "SKB",
    "SH5",
    "H5B",
    "H5W",
    "S5K",
    "5KB",
    "QN",
    "QNB",
    "OM",
    "OMB",
    "TI",
    "TIB"
  ],

  multiRace: [
    "DB",
    "P3",
    "P4",
    "P5",
    "P6",
    "P7",
    "P8",
    "P9",
    "P10",
    "P11",
    "P12",
    "A3",
    "A4",
    "A5",
    "A6",
    "A7",
    "A8",
    "A9",
    "A10",
    "A11",
    "A12",
    "L3",
    "L4",
    "L5",
    "L6",
    "L7",
    "L8",
    "L9",
    "L10",
    "GS"
  ],
  pickBet: [
    "DB",
    "P3",
    "P4",
    "P5",
    "P6",
    "P7",
    "P8",
    "P9",
    "P10",
    "P11",
    "P12"
  ],
  pickBetScratchRules: [
    "DB",
    "P3",
    "P4",
    "P5",
    "P6",
    "A3",
    "A4",
    "A5",
    "A6",
    "GS"
  ],
  pickBetSub: ["P4", "P5", "P6", "A4", "A5", "A6", "GS"],
  keyBoxWheel: [
    "EXB",
    "EXK",
    "EXW",
    "EKB",
    "TRB",
    "TRK",
    "TRW",
    "TKB",
    "SUB",
    "SUK",
    "SUW",
    "SKB",
    "H5B",
    "S5K",
    "H5W",
    "5KB",
    "QNB",
    "QNW",
    "OMB",
    "OMW",
    "TIB",
    "TIW"
  ]
};

export const getPickAll = (
  pickType: string,
  startNumber: number,
  endNumber: number
): Array<string> =>
  new Array(endNumber - startNumber + 1)
    .fill(pickType)
    .map((value: string, index) => `${value}${index + startNumber}`);

export const isPickBetWithInvalidResults = (wagerType: string) =>
  ["GS", ...getPickAll("L", 3, 20)].includes(wagerType);

export const isPicketBetScratchRules = (betTypesCode: WagerTypeCodesEnum) =>
  betTypesCodes.pickBetScratchRules.includes(betTypesCode);

export const isPickBetSub = (betTypesCode: WagerTypeCodesEnum) =>
  betTypesCodes.pickBetSub.includes(betTypesCode);

export const isPickBetWager = (betTypeCode: WagerTypeCodesEnum) =>
  betTypesCodes.pickBet.includes(betTypeCode);

export const isMultiRaceBet = (betTypeCode: WagerTypeCodesEnum) =>
  betTypesCodes.multiRace.includes(betTypeCode);

export const isExoticBet = (betTypeCode: WagerTypeCodesEnum) =>
  betTypesCodes.exotic.includes(betTypeCode);

export const useIsXsellBlacklistedBet = (
  betTypeCode: WagerTypeCodesEnum,
  isURPPpOnXSellEnabled: boolean
) => {
  const blacklistedBets = useSelector(getXsellBlacklistedMyBets);
  return (
    !isURPPpOnXSellEnabled &&
    blacklistedBets.blacklistedBets.includes(betTypeCode)
  );
};

export const getWinnerBI = (
  results: RaceInfoMyBetsResults,
  finishPosition: number = 1
) => {
  const runners = get(results, "runners") || [];

  return runners.reduce((acc: RaceInfoMyBetsRunner[], runner) => {
    if (runner.finishPosition === finishPosition) {
      const currentRunner = { ...runner };
      return [...acc, currentRunner];
    }

    return acc;
  }, []);
};

export const shouldShowWillPays = (
  races: RaceInfoMyBets[],
  selection: Selections[],
  results: RaceInfoMyBetsResults[],
  finalBettedLeg: number
) => {
  let showWillPays = false;
  const lastFinishedRace = first(
    races
      .filter((race) => race.status.code === "RO")
      .sort((a, b) => +b.number - +a.number)
  );
  const lastFinishedLeg = lastFinishedRace ? lastFinishedRace.number : 0;
  const currentLeg = +lastFinishedLeg + 1;
  const isTrackOver = races.findIndex((race) => +race.number === 1) === -1;
  if (!isTrackOver && finalBettedLeg - currentLeg === 0 && results) {
    const finishedResults = results
      ? results.filter((result) => result !== null)
      : [];
    if (finishedResults.length === selection.length - 1) {
      const isWinningSoFar = Array(selection.length - 1);
      // eslint-disable-next-line
      finishedResults.forEach((result, index) => {
        if (result.runners) {
          const winnerBI = getWinnerBI(result);
          isWinningSoFar[index as number] = !!selection[
            index as number
          ].runners.find(
            (runner: SelectionRunner) =>
              !!winnerBI &&
              (+runner.number === +get(winnerBI, "[0].biNumber", -1) ||
                +runner.number === +get(winnerBI, "[1].biNumber", -1))
          );
        }
      });
      if (isWinningSoFar.every((w) => w)) {
        showWillPays = true;
      }
    }
  }
  return showWillPays;
};

export const getActiveLegs = (
  races: RaceInfoMyBets[],
  raceNumber: number,
  length: number
) =>
  races
    .filter(
      (race) =>
        race.number >= +raceNumber && race.number <= +raceNumber + length - 1
    )
    .filter((race) => race.status.code !== RaceStatusEnum.RACE_OFFICIAL);

export const getRacesInPickBets = (
  races: RaceInfoMyBets[],
  raceNumber: number,
  length: number
) =>
  races.filter(
    (race) =>
      race.number >= +raceNumber && race.number <= +raceNumber + length - 1
  );

export const getRaceProps = ({
  isMultiRace,
  isSettledBet,
  legRaces,
  currentRace,
  currentLeg,
  bet,
  currentRaceDate,
  betDate,
  videoFeedback,
  raceNumber,
  isCancelled
}: Props) => {
  let statusCode: RaceStatusEnum = get(currentRace, "status.code");
  const raceType: RaceTypeCodeEnum = get(bet, "wagers[0].raceTypeAbbreviation");

  if (currentRace && !statusCode) {
    statusCode = RaceStatusEnum.OPEN;
  }

  if (
    (!currentRace && !statusCode) ||
    isBefore(new Date(betDate), new Date(currentRaceDate))
  ) {
    statusCode = RaceStatusEnum.RACE_OFFICIAL;
  }

  const hasLiveVideo =
    currentRaceDate === betDate &&
    (isMultiRace && currentLeg && !isSettledBet
      ? videoFeedback.indexOf("AVAILABLE") === 0 &&
        currentLeg.status.code !== RaceStatusEnum.RACE_OFFICIAL &&
        currentLeg.status.code !== RaceStatusEnum.MANUALLY_CLOSED
      : videoFeedback.indexOf("AVAILABLE") === 0 &&
        statusCode !== RaceStatusEnum.RACE_OFFICIAL &&
        statusCode !== RaceStatusEnum.MANUALLY_CLOSED);

  if (isMultiRace && currentLeg && (!isSettledBet || isCancelled)) {
    const currentLegNumber =
      legRaces &&
      currentLeg &&
      legRaces.findIndex((race) => race.number === currentLeg.number) + 1;

    const mtp = isCancelled
      ? get(currentRace, "mtp", 0)
      : get(currentLeg, "mtp", 0);

    const legStatusCode = isCancelled
      ? get(currentRace, "status.code")
      : get(currentLeg, "status.code");

    const currentRaceNumber = currentLegNumber + raceNumber - 1;

    return {
      mtp,
      postTime: currentLeg.postTime,
      statusCode,
      legStatusCode,
      legNumber: currentLegNumber,
      currentRaceNumber,
      hasLiveVideo: hasLiveVideo && mtp <= 60,
      raceType
    };
  }

  const mtp = get(currentLeg, "mtp", null) || get(currentRace, "mtp", 0);
  return {
    mtp,
    postTime: get(bet, "wagers[0].racePostTime"),
    statusCode,
    legStatusCode: null,
    legNumber: 0,
    currentRaceNumber: raceNumber,
    hasLiveVideo: hasLiveVideo && mtp <= 60,
    raceType
  };
};

export const getRaceStatus = ({
  isAllRunnersScratched,
  legContainsScratch,
  isMultiRace,
  selectionIndex,
  selection,
  races,
  raceNumber,
  selectionLength,
  isCanceled,
  betStatusName,
  wagerType,
  favoriteRunner,
  shouldShowReplacement
}: CheckRaceStatus) => {
  const winnerBI = !!find(selection, { isWinner: true });
  const isRaceActive = betStatusName?.toLowerCase() === "active";
  const isInvalidWagerType = isPickBetWithInvalidResults(wagerType);
  let raceStatus: RaceLegStatus = "open";

  if (isMultiRace) {
    if (isRaceActive) {
      if (get(races, "length", 0) > 0) {
        const legRaces = getRacesInPickBets(races, raceNumber, selectionLength);
        const legStatus = get(legRaces, `${selectionIndex}.status.code`);
        const legResults = get(legRaces, `${selectionIndex}.results`);
        const activeLegs = getActiveLegs(races, raceNumber, selectionLength);
        const currentActiveLeg = first(activeLegs);

        if (winnerBI) {
          raceStatus = "win";
        } else if (!legResults && legStatus === RaceStatusEnum.RACE_OFFICIAL) {
          raceStatus = "raceOff";
        } else if (isAllRunnersScratched || legContainsScratch) {
          raceStatus = "attention";
        } else if (
          (!legStatus || legStatus === RaceStatusEnum.RACE_OFFICIAL) &&
          !winnerBI
        ) {
          raceStatus = "lost";
        } else if (
          activeLegs.length > 0 &&
          currentActiveLeg?.number &&
          +currentActiveLeg?.number === +raceNumber + selectionIndex &&
          currentActiveLeg.status.code !== RaceStatusEnum.MANUALLY_CLOSED
        ) {
          raceStatus = "raceOff";
        }
      } else if (selectionIndex === 0) {
        raceStatus = "raceOff";
      }
    } else if (!isCanceled) {
      const isUnknownStatus = get(selection, "0.isWinner") === null;
      if (isUnknownStatus) {
        raceStatus = "unknown";
      } else if (winnerBI) {
        raceStatus = "win";
      } else {
        raceStatus = "lost";

        if (
          favoriteRunner &&
          favoriteRunner.isWinner &&
          isAllRunnersScratched &&
          shouldShowReplacement
        ) {
          raceStatus = "win";
        }
      }
    }
  }

  if (
    !["open", "raceOff", "attention"].includes(raceStatus) &&
    isInvalidWagerType
  ) {
    raceStatus = "unknown";
  }

  return raceStatus;
};

export const getRacesIds = (bets: WroWagerGroup[] = []): Array<string> =>
  bets.map((bet) => {
    const [, trackCode] = bet.value.split("|");
    return trackCode;
  });

export const isRaceOff = (status: RaceStatusEnum): boolean =>
  status === RaceStatusEnum.RACE_OFF || status === RaceStatusEnum.RACE_OFFICIAL;
