import React from "react";

import formatCurrency from "@tvg/formatter/currency";
import { pluralize } from "@tvg/utils/generalUtils";
import { Header } from "@tvg/design-system";
import { Icon, Paragraph } from "@tvg/design-system/web";
import {
  CloseButton,
  HeaderWrapper,
  SuccessWrapper
} from "./styled-components";
// Utils

interface RepeatBetModalHeaderProps {
  showBalance: boolean;
  placeBetSuccess: boolean;
  repetitions: number;
  userBalance: number;
  onClose?: () => void;
}

const RepeatBetModalHeader: React.FC<RepeatBetModalHeaderProps> = ({
  showBalance,
  placeBetSuccess,
  repetitions,
  userBalance,
  onClose
}) => (
  <HeaderWrapper placeBetSuccess={placeBetSuccess} data-qa-label="page-header">
    {placeBetSuccess ? (
      <>
        <Icon
          name="success"
          size="s"
          mr="space-3"
          lineColor="--fd-colors-system-positive-content-accent"
          backgroundColor="--fd-colors-system-positive-background-subtle"
        />
        <Header
          qaLabel="page-header-title"
          tag="h1"
          fontFamily="bold"
          fontSize={18}
          color="green.700"
        >
          {`${pluralize(repetitions, "Bet", true, "s", "x")} Placed`}
        </Header>
      </>
    ) : (
      <SuccessWrapper>
        <Header
          qaLabel="page-header-title"
          tag="h1"
          fontFamily="bold"
          fontSize={18}
          color="grey.900"
        >
          Repeat Bet
        </Header>
        {showBalance && (
          <Paragraph qaLabel="rbm-subtitle" color="grey.800" fontSize="14px">
            <>BALANCE {formatCurrency(userBalance)}</>
          </Paragraph>
        )}
      </SuccessWrapper>
    )}

    <CloseButton onClick={onClose} data-qa-label="page-header-close-button">
      <Icon size="m" lineColor="--fd-colors-content-default" name="close" />
    </CloseButton>
  </HeaderWrapper>
);

export default RepeatBetModalHeader;
