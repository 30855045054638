export const SelectionSourceMapping: { [key: string]: string } = {
  same_race_parlay: "same_race_parlay_card",
  quick_picks: "quick_picks"
};

export const getSelectionSourceFromModule = (
  betSourceModule: string | undefined | null
) => {
  if (!betSourceModule) return undefined;

  return SelectionSourceMapping[betSourceModule];
};

export const CustomBetMapping: { [key: string]: string } = {
  same_race_parlay: "SRP",
  quick_picks: "QP"
};

export const getCustomBetTypeFromModule = (
  betSourceModule: string | undefined | null
) => {
  if (!betSourceModule) return undefined;

  return CustomBetMapping[betSourceModule];
};
