import { Dispatch } from "redux";
import { isXSell } from "@tvg/sh-utils/mobileUtils";
import { getWalletBalances } from "@tvg/api/aw";
import { getAuthTokenCookie } from "@tvg/sh-utils/sessionUtils";
import { getUMEvent } from "../../mediator/events";
import { UMEvents } from "../../mediator/types";
import mediator from "../../mediator";
import { setRacingWallet } from "../../../hooks/useWallet/hooks/useSubscriptions/utils/completion/getBalance/setRacingWallet";

export const getBalance = (dispatch?: Dispatch, accountNumber?: string) => {
  if (isXSell()) {
    const authToken = getAuthTokenCookie();
    if (authToken && dispatch) {
      getWalletBalances(getAuthTokenCookie())
        .then((balances) => {
          const formattedBalances = new Set(
            balances.map(({ account_type, ...rawBalances }) => ({
              ...rawBalances,
              accountType: account_type
            }))
          );

          setRacingWallet(formattedBalances, dispatch, accountNumber);
        })
        .catch(() => console.warn("[UM] Get balance failed"));
    }
  } else {
    const event = getUMEvent(UMEvents.GetBalance);
    const payload = accountNumber
      ? {
          gtm: {
            accountNumber
          }
        }
      : {};
    mediator.dispatch({
      type: event.trigger,
      payload
    });
  }
};
