import styled from "styled-components";

export const ConfirmBetModalFooterWrapper = styled.div`
  margin-top: 15px;
`;

export const BetConfirmationContainer = styled.div`
  padding: ${({ theme }) => theme.space["space-4"]};
  flex-direction: column;
  display: flex;
`;

export const BetSummarySpecialBetsContainer = styled.div`
  & > div {
    margin-top: ${({ theme }) => theme.space["space-3"]};
  }
`;

export const AlertMessageContainer = styled.div`
  display: flex;
`;
