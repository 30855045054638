import styled, { css } from "styled-components";
import { space } from "styled-system";

export const StyledAccordion = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  ${space}
`;

export const StyledAccordionItem = styled.li<{ hasBorder?: boolean }>`
  display: flex;
  flex-direction: column;
  background-color: var(--fd-colors-background-surface);
  ${({ hasBorder }) =>
    hasBorder &&
    css`
      border-bottom: 1px solid var(--fd-colors-border-subtle);
    `}
`;

export const AccordionHeader = styled.button`
  border: none;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  gap: var(--fd-space-space-4);
  padding: var(--fd-space-space-4);
  transition: background-color 0.2s ease-in-out;

  @media (hover: hover) {
    &:hover {
      background-color: var(--fd-colors-background-hover);
    }
  }

  &:active:not(:disabled) {
    background-color: var(--fd-colors-background-hover);
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const ContentWrapper = styled.div<{ textTruncate?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 0 var(--fd-space-space-4) var(--fd-space-space-4)
    var(--fd-space-space-4);

  ${({ textTruncate }) =>
    textTruncate &&
    css`
      & > p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: var(--fd-space-space-4);
        max-width: 100%;
        max-height: 18px;
      }
    `}
`;

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  width: 20px;
`;

export const ContentPreview = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: var(--fd-space-space-4);
`;
