import styled from "styled-components";

export const CustomDropdown = styled.div`
  position: relative;
  width: 100%;
  min-width: 123px;
`;

export const Label = styled.span<{ isDisabled: boolean }>`
  padding: 12px 16px;
  color: ${({ isDisabled }) =>
    isDisabled
      ? `var(--fd-colors-component-data-input-content-disabled)`
      : `var(--fd-colors-component-data-input-content-base)`};

  &:hover {
    cursor: default;
  }
`;

export const SelectOptions = styled.div`
  width: max-content;
  position: absolute;
  top: 58px;
  left: 0;
  background-color: var(--fd-colors-component-data-input-background-base);
  box-shadow: var(--fd-shadows-elevations-bottom-mid);
  border-radius: 4px;
  z-index: 1;
`;

export const SelectOption = styled.div`
  padding: 12px 12px 0;

  > div {
    margin-bottom: 12px;
  }

  label {
    margin-right: 20px;
  }
`;
