import React, { useState, useRef, useEffect } from "react";
import { animated, useSpring, config } from "@react-spring/web";
import { Header } from "../Typography";
import { Icon } from "../Icon";
import {
  AccordionHeader,
  StyledAccordionItem,
  ContentContainer,
  ContentWrapper,
  StyledAccordion,
  IconContainer
} from "./styled-components";
import type { AccordionItemProps, AccordionProps } from "./types";

const AnimatedContent = animated(ContentContainer);
const AnimatedIconContainer = animated(IconContainer);

export const Accordion: React.FC<AccordionProps> = ({
  qaLabel = "accordion",
  children,
  ordered = false,
  allowMultiple = false,
  defaultOpenIndices = [],
  ...rest
}) => {
  const [openIndices, setOpenIndices] = useState<number[]>(defaultOpenIndices); // Track open accordions

  const handleToggle = (index: number) => {
    if (allowMultiple) {
      setOpenIndices(
        (prev) =>
          prev.includes(index)
            ? prev.filter((i) => i !== index) // Remove index if already open
            : [...prev, index] // Add index to openIndices if not already open
      );
    } else {
      setOpenIndices((prev) => (prev.includes(index) ? [] : [index]));
    }
  };

  return (
    <StyledAccordion
      data-qa-label={qaLabel}
      as={ordered ? "ol" : "ul"}
      {...rest}
    >
      {React.Children.map(children, (child, index) =>
        React.cloneElement(child as React.ReactElement, {
          isOpen: openIndices.includes(index), // Pass isOpen prop to AccordionItem
          onClick: (event: React.MouseEvent) => {
            // Pass onClick function to AccordionItem and execute callback if it exists
            handleToggle(index);
            if ((child as React.ReactElement).props.onClick) {
              (child as React.ReactElement).props.onClick(event);
            }
          }
        })
      )}
    </StyledAccordion>
  );
};

export const AccordionItem: React.FC<AccordionItemProps> = ({
  qaLabel = "accordion-item",
  title,
  children,
  isOpen,
  onClick,
  hasBorder = true,
  showPreview = false,
  onAnimationEnd,
  isDisabled = false
}) => {
  const contentRef = useRef<HTMLDivElement>(null);

  const [style, animate] = useSpring(
    () => ({
      opacity: 0,
      height: 0,
      config: {
        tension: 200,
        friction: 25,
        clamp: true
      }
    }),
    []
  );

  const iconRotation = useSpring({
    transform: isOpen ? "rotate(180deg)" : "rotate(0deg)",
    config: config.stiff
  });

  const updateHeight = () => {
    if (contentRef.current) {
      const startHeight = showPreview ? 34 : 0;
      const startOpacity = showPreview ? 1 : 0;

      animate.start({
        height: isOpen ? contentRef.current.offsetHeight : startHeight,
        opacity: isOpen ? 1 : startOpacity,
        onRest: () => {
          if (onAnimationEnd) {
            onAnimationEnd(isOpen);
          }
        }
      });
    }
  };

  useEffect(() => {
    updateHeight();

    window.addEventListener("resize", updateHeight);

    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, [animate, isOpen]);

  return (
    <StyledAccordionItem data-qa-label={qaLabel} hasBorder={hasBorder}>
      <AccordionHeader disabled={isDisabled} onClick={onClick}>
        <Header
          tag="h3"
          fontSize="16px"
          fontWeight={700}
          lineHeight="20px"
          qaLabel={`${qaLabel}-title`}
          textAlign="left"
          m={0}
        >
          {title}
        </Header>

        {!isDisabled && (
          <AnimatedIconContainer style={iconRotation}>
            <Icon
              lineColor="--fd-colors-content-default"
              size="m"
              name="chevronDown"
            />
          </AnimatedIconContainer>
        )}
      </AccordionHeader>

      <AnimatedContent data-qa-label={`${qaLabel}-content`} style={style}>
        <ContentWrapper
          data-qa-label={`${qaLabel}-content-wrapper-${isOpen ? "open" : "closed"}`}
          ref={contentRef}
          textTruncate={showPreview && !isOpen}
        >
          {children}
        </ContentWrapper>
      </AnimatedContent>
    </StyledAccordionItem>
  );
};

export type { AccordionItemProps, AccordionProps };
