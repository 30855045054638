import tvgConf from "@tvg/conf";
import { SSNObject, UserDetails, SignupDetails } from "@tvg/ts-types/User";
import { UamPreference } from "frontend-pctr/src/store/actions/types";
import getProtocol from "../protocolSetter";
import requester from "../requester";
import { serviceResponse } from "../types";

export const TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION = 158408;
export const FDR_EXPIRED_TOKEN_EXCEPTION = 158405;
export const FDR_USER_NOT_VERIFIED_EXCEPTION = 126777;
export const INELIGIBLE_RESIDENCY_STATE_FOR_RACING = 136527;
export const INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE =
  "INELIGIBLE_RESIDENCY_STATE_FOR_RACING";
export const TERMS_AND_CONDITIONS_OR_ACKNOWLEDGES_NOT_ACCEPTED = 136528;
export const INELIGIBLE_LOCATION = 136530;
export const GEO_COMPLY_EXPIRED = 136531;
export const GEO_COMPLY_REJECTED = 136524;
export const INSUFFICIENT_FUNDS = 136518;
export const WAGER_LIMIT = 136521;
export const WAGER_SIZE_LIMIT = 136522;
export const UNVERIFIED_ACCOUNT = 136523;

const uamService: string = "service.uam";
const getUamServiceUrl = (): string =>
  `${getProtocol()}${tvgConf().config(uamService)}`;

const getUserPrefs = (
  accountNumber: string
): Promise<
  serviceResponse<{
    preferences: UamPreference[];
  }>
> =>
  requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const setUserPref = (
  accountNumber: string,
  prefName: string,
  prefValue: unknown
): Promise<serviceResponse> =>
  requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      preferences: [
        {
          description: prefName,
          metadata: prefValue
        }
      ]
    }
  });

const getUserRaceFilters = (accountNumber: string): Promise<serviceResponse> =>
  requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences/raceFilters`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getUserRegionFilters = (
  accountNumber: number
): Promise<serviceResponse> =>
  requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences/regionFilters`,
    method: "get",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() }
  });

/**
 * Saves race filters on the user preferences
 * @param filters - an array with the filter code strings (eg. ["T", "H"]
 * @param accountNumber - the account number
 */
const saveRaceFilters = (
  filters: string[],
  accountNumber: string
): Promise<serviceResponse> => {
  const metadata = {
    group: "ALL",
    breed: filters
  };

  return requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      preferences: [
        {
          description: "raceFilters",
          metadata: JSON.stringify(metadata)
        }
      ]
    }
  });
};

/**
 * Saves region filters on the user preferences
 * @param filters - an array with the filter code strings (eg. ["USA", "INT"]
 * @param accountNumber - the account number
 */
const saveRegionFilters = (
  filters: string[],
  accountNumber: string
): Promise<serviceResponse> => {
  const metadata = {
    group: "ALL",
    regions: filters
  };

  return requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/preferences`,
    method: "put",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      preferences: [
        {
          description: "regionFilters",
          metadata: JSON.stringify(metadata)
        }
      ]
    }
  });
};

/**
 * Saves region filters on the user preferences
 * @param accountNumber - the account number
 * @param exclusionType - exclusion type (eg. ["TIMOUT", "SELF_EXCLUSION", "SUSPEND"]
 * @param periodInDays - period in days (null case 'exclusionType'==='SUSPEND')
 */
const selfExclusion = (
  accountNumber: string,
  exclusionType: string,
  periodInDays: number | undefined
): Promise<serviceResponse> =>
  requester()({
    url: `${getUamServiceUrl()}/users/${accountNumber}/status/self-exclusion`,
    method: "post",
    withCredentials: true,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      exclusionType,
      periodInDays
    }
  });

const getUserProfile = (userId: string): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/users/${userId}/profile`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });

const getFDRUserProfile = (): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/FDR/users/profile`,
    headers: { "x-tvg-context": tvgConf().context() },
    withCredentials: true
  });

const getPromoValidation = (promoCode: string): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/registration/promocodes/validation?promocode=${promoCode}`,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getZipCodeValidation = (zipcode: number): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/registration/zipcodes/validation/${zipcode}`,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const getStateValidation = (
  stateAbbreviation: number
): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/states/${stateAbbreviation}`,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const postUserRegistration = ({
  userDetails,
  signupDetails,
  enableOnfido
}: {
  userDetails: UserDetails;
  signupDetails: SignupDetails;
  enableOnfido: boolean;
}): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getUamServiceUrl()}/users`,
    headers: { "x-tvg-context": tvgConf().context(), "x-onfido": enableOnfido },
    data: {
      userDetails,
      signupDetails
    }
  });

const postGetSSNValidation = ({
  ssnObject
}: {
  ssnObject: SSNObject;
}): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getUamServiceUrl()}/registration/ssn/validation`,
    headers: { "x-tvg-context": tvgConf().context() },
    data: ssnObject
  });

const postOnfidoCheck = ({
  accountId,
  onfidoApplicantId,
  verificationUuid
}: {
  accountId: string;
  onfidoApplicantId: string;
  verificationUuid: string;
}): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getUamServiceUrl()}/users/${accountId}/onfido-check`,
    headers: { "x-tvg-context": tvgConf().context() },
    data: {
      onfidoApplicantId,
      verificationUuid
    }
  });

const getOnfidoCheck = ({
  accountId,
  checkId
}: {
  accountId: string;
  checkId: string;
}): Promise<serviceResponse> =>
  requester()({
    method: "get",
    url: `${getUamServiceUrl()}/users/${accountId}/onfido-check/${checkId}`,
    headers: { "x-tvg-context": tvgConf().context() }
  });

const postFDRProfileMigration = (
  userId: string,
  stateAbbr: string,
  email: string
): Promise<serviceResponse> =>
  requester()({
    method: "post",
    url: `${getUamServiceUrl()}/FDR/users`,
    headers: { "x-tvg-context": tvgConf().context(), "ghost-account": true },
    withCredentials: true,
    data: {
      externalUserId: userId,
      userDetails: {
        email,
        homeAddress: {
          state: stateAbbr
        }
      },
      signupDetails: {
        rfr: document.cookie.match(`(^|;)\\s*RFR\\s*=\\s*([^;]+)`)?.pop() || ""
      }
    }
  });

const UAM_SERVICE = {
  getUserRaceFilters,
  getUserRegionFilters,
  saveRaceFilters,
  saveRegionFilters,
  getUserPrefs,
  setUserPref,
  selfExclusion,
  getUserProfile,
  getFDRUserProfile,
  getPromoValidation,
  getZipCodeValidation,
  getStateValidation,
  postUserRegistration,
  postGetSSNValidation,
  postOnfidoCheck,
  getOnfidoCheck,
  postFDRProfileMigration,
  // Constrains
  TVG_ACCOUNT_ID_NOT_FOUND_EXCEPTION,
  FDR_USER_NOT_VERIFIED_EXCEPTION,
  INELIGIBLE_RESIDENCY_STATE_FOR_RACING,
  INELIGIBLE_RESIDENCY_STATE_FOR_RACING_MESSAGE,
  TERMS_AND_CONDITIONS_OR_ACKNOWLEDGES_NOT_ACCEPTED,
  INELIGIBLE_LOCATION,
  GEO_COMPLY_EXPIRED,
  GEO_COMPLY_REJECTED,
  INSUFFICIENT_FUNDS,
  WAGER_LIMIT,
  WAGER_SIZE_LIMIT,
  UNVERIFIED_ACCOUNT
};

export default UAM_SERVICE;
