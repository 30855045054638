import mediator from "@tvg/mediator";
import { isMobile, isXSell } from "@tvg/sh-utils/mobileUtils";
import { get } from "lodash";
import { xSellConfig, webConfig, hybridConfig } from "./apps";
import { AppConfig, MediatorChannel } from "./apps/types";

let mediatorInstance: typeof mediator;
let eventMappedInstance: AppConfig | null = null;

const getMediatorChannel = (): MediatorChannel => {
  if (!mediatorInstance) {
    if (isMobile()) {
      mediatorInstance = mediator.ios;
      eventMappedInstance = isXSell() ? xSellConfig : hybridConfig;
    } else {
      mediatorInstance = mediator.base;
      eventMappedInstance = webConfig;
    }
  }

  return {
    dispatch: (action) => {
      const type = get(eventMappedInstance?.triggers, action?.type);
      if (type) {
        mediatorInstance.dispatch({
          type,
          payload: action.payload
        });
      } else {
        console.warn(`[UM] Can't find event ${action?.type} to dispatch`);
      }
    },
    subscribe: (eventType, callback) => {
      const type = get(eventMappedInstance?.subscriptions, eventType);
      if (type) {
        mediatorInstance.subscribe(type, callback);
      } else {
        console.warn(`[UM] Can't find event ${eventType} to subscribe`);
      }
    }
  };
};

export default getMediatorChannel();
